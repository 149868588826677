import React from "react";
import { Loan } from "../../../models/loan.model";
import { fillEmptyData } from "../../../shared/utils/fillEmptyData";
import { getFullName } from "../../../shared/utils/getFullName";
import { removeUnderscore } from "../../../shared/utils/removeUnderscore";

export const columns = [
    {
      title: "Loan ID",
      dataIndex: "code",
      render: (code: string) => code?.toUpperCase(),
      sorter: true,
    },
    {
      title: "Partner ID",
      dataIndex: "laf",
      render: (laf: string) => fillEmptyData(laf?.toUpperCase()),
    },
    {
      title: "Customer Name",
      dataIndex: "name",
      render: (_: string, record: Loan) => record?.customer?.getName(),
      sorter: true,
    },
    {
      title: "Branch",
      dataIndex: "branch",
      render: (_: string, record: Loan) => record?.branch?.name,
    },

    {
      title: "Center",
      dataIndex: "centerName",
      sorter: true,
    },
    {
      title: "CRO",
      dataIndex: "createdByFirstName",
      sorter: true,
      render: (_: string, record: Loan) => getFullName(record?.createdBy),
    },
    {
      title: "Loan Type",
      dataIndex: "loanTypeName",
      sorter: true,
    },

    {
      title: "Total Amount",
      dataIndex: "requestedLoanAmt",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (_: string, record: Loan) => {
        const className =
          "active-status--" + record.status?.split("_")?.join("-");
        return (
          <>
            <span
              className={`text-success text-capitalize active-status ${className}`}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              {removeUnderscore(record?.status ?? "")}
            </span>
            {/* <span
            className={`text-success text-capitalize active-status ${className}`}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          > */}
            {/* {removeUnderscore(record.getLoanStatusLabel() ?? "")} */}
            {/* </span> */}
          </>
        );
      },
    },

    {
      title: "Preferred channel",
      dataIndex: "organizationName",
      sorter: true,
    },
  ];
