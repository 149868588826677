/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Drawer,
  Table,
  TableProps,
} from "antd";
import "./loanType.scss";
import { LoanParams } from "../../../models/loan.model";
import { ToggleDrawer } from "../../../shared/types/toggleDrawer";
import { columns } from "./columns";
import { generatePath, useNavigate } from "react-router-dom";
import * as AppRoutes from "../../../routes/routeConstants/appRoutes";
import { MetaService } from "../../../services/Meta/meta.service";
import { LoanTypeModel } from "../../../models/loanType.model";
import { QueryParams } from "../../../models/query.params.model";
import { StepStatus } from "../../../enums/stepStatus.enum";
import LoanTypeDetailForm from "./LoanTypeDetailsForm";
import LoanTypeConditionForm from "./LoanTypeConditionForm";
import LoanTypeConfigurationForm from "./LoanTypeConfigurationForm";
import ColumnOptions from "../../../shared/components/ColumnOptions";
import useTableConfig from "../../../shared/hooks/useTableConfig";
import TabExtraContent from "../../../shared/components/TabExtraContent";
import useQueryParams from "../../../shared/hooks/useQueryParams";
import './loanType.scss'
import { ColumnsType, SorterResult } from "antd/lib/table/interface";
import useSorting from "../../../shared/hooks/useSorting";
import { getColumnKeys, getColumnTitles, getDefaultColumns, getDeletedColumns, hasColumnDifference } from "../../../shared/utils/columnUtil";
import { ColumnOrder } from "../../../enums/columnOrder.enum";
import { LocalStorageHelper } from "../../../shared/utils/localStorageHelper";
import { ColumnsService } from "../../../services/Columns/columns.service";
import { LocalStorage } from "../../../enums/localStorage.enum";

interface LoanTypeProps {
  params: LoanParams;
  addFormVisible: boolean;
  filtersVisible: boolean;
  settingsVisible: boolean;
  toggleAddForm: ToggleDrawer;
  toggleFiltersVisibility: ToggleDrawer;
  toggleSettingsVisibility: ToggleDrawer;
}

const sortBy = {}
function LoanTypes(props: LoanTypeProps) {
  const [currentStep, setCurrentStep] = useState(1);
  const [loanTypeId, setLoanTypeId] = useState("");

  const toggleLoanType = (loanId: string) => setLoanTypeId(loanId);

  const {
    addFormVisible,
    settingsVisible,
    params,
    toggleAddForm,
    toggleSettingsVisibility,
  } = props;
  const navigate = useNavigate();
  const {
    getLoanTypesListing,
    loading: metaLoading,
  } = MetaService();
  const [loanTypesOptions, setLoanTypeOptions] = useState<LoanTypeModel[]>([]);
  const { columnConfig, setColumnConfig, getColumnOrder } = useTableConfig();
  const [filteredColumns, setFilteredColumns] = useState<string[]>(
    columnConfig?.loanTypes ?? []
  );
  const handleRowChange = (record: LoanTypeModel, rowIndex?: number) => ({
    onClick: () =>
      navigate(
        generatePath(AppRoutes.LOAN_TYPE_DETAIL, {
          loanTypeId: String(record?.loanTypeId),
        })
      ),
  });
  const fetchLoanTypes = async (params: QueryParams) => {
    const data = await getLoanTypesListing(params);

    data && setLoanTypeOptions(data);
  };

  const { params: loanTypeParams, updateParams, handleSearch: handleLoanTypeSearch} = useQueryParams<LoanParams>({
    params: new LoanParams(),
  });

  const toggleSteps = (status: string) =>
    setCurrentStep(
      StepStatus.NEXT === status ? currentStep + 1 : currentStep - 1
    );

    const { updateSortData } = useSorting({ sortBy });

    const handleChange: TableProps<LoanTypeModel>["onChange"] = (
      pagination,
      _,
      sorter
    ) => {
      const { sortBy, sortDirection } = updateSortData(
        sorter as SorterResult<LoanTypeModel>
      );
      updateParams({
        page: pagination?.current,
        sortBy,
        sortDirection,
      });
    };

  useEffect(() => {
    const updateParams = loanTypeParams?.searchText
      ? { page: 1, searchText: loanTypeParams?.searchText }
      : params;
    fetchLoanTypes(updateParams);
    setLoanTypeId("");
  }, [params, loanTypeParams.searchText]);

  const setColumns = () => {
    if (originalOrder && originalOrder.length > 0) {
      const updatedColumns = getDefaultColumns(columns, storedColumnOrder, storedColumnKeys, originalOrder);
      setDefaultColumns(updatedColumns);
    } else {
      setDefaultColumns(columns);
    }
  }
  useEffect(() => {
    fetchColumnOrder()
  }, []);

  const fetchColumnOrder = async () => {
    const columnOrder = await getColumns({
      pageName: ColumnOrder.LOAN_TYPES,
      employeeId: employee.id
    })
    if (!columnOrder) {
      publishColumns()
    }
    setOriginalOrder(columnOrder?.columnArray ?? [])
    setColumnConfig(ColumnOrder.LOAN_TYPES, columnOrder?.columnArray ?? [])
  }

  const setDeletedColumns = () => {
    setFilteredColumns(getColumnTitles(getDeletedColumns(columns, originalOrder)))
  }
  const tableColumnTitles = getColumnTitles(columns)
    const storedColumnOrder = getColumnOrder(ColumnOrder.LOAN_TYPES);
    const storedColumnKeys = getColumnKeys(storedColumnOrder)
    const employee = LocalStorageHelper.getItem(LocalStorage.EMPLOYEE);
    const [originalOrder, setOriginalOrder] = useState<string[]>([])
    const { createColumns, getColumns, updateColumns } = ColumnsService()

  const publishColumns = async () => {
    await createColumns({
     pageName: ColumnOrder.LOAN_TYPES,
     columnArray: tableColumnTitles,
     employeeId: employee?.id
   })
  }

  useEffect(() => {
    if (hasColumnDifference(columns, defaultColumns)) {
      publishColumns();
    }
  }, []);

  useEffect(() => {
    if (originalOrder && originalOrder.length) {
      setColumns();
      setDeletedColumns()
    }
  }, [originalOrder]);


  const updateColumn = async (columns: ColumnsType<LoanTypeModel>) => {
    await updateColumns({
      columnArray: getColumnTitles(columns),
      pageName: ColumnOrder.LOAN_TYPES
    })
  }

  const [defaultColumns, setDefaultColumns] = useState(
    getDefaultColumns(columns, storedColumnOrder, storedColumnKeys, originalOrder)
  );

  const steps = [
    {
      step: 1,
      component: (
        <LoanTypeDetailForm
          toggleStep={toggleSteps}
          currentStep={currentStep}
          loanTypeId={loanTypeId}
          toggleAddForm={toggleAddForm}
          toggleLoanType={toggleLoanType}
        />
      ),
    },
    {
      step: 2,
      component: (
        <LoanTypeConditionForm
          loanTypeId={loanTypeId}
          currentStep={currentStep}
          toggleStep={toggleSteps}
        />
      ),
    },
    {
      step: 3,
      component: (
        <LoanTypeConfigurationForm
          currentStep={currentStep}
          loanTypeId={loanTypeId}
          toggleStep={toggleSteps}
          toggleAddForm={() => {
            fetchLoanTypes(params);
            toggleAddForm();
          }}
        />
      ),
    },
  ];

  return (
    <div className="loan-types">
      <div className="loan-types-extra-content">
      <TabExtraContent
      searchProps={{
        onSearch: handleLoanTypeSearch,
        placeholder: "Search Loan Type",
        value: loanTypeParams.searchText
      }}
      />
      </div>
      <Table
        columns={defaultColumns.filter(
          (col) => !filteredColumns.includes(col.title as string)
        )}
        dataSource={loanTypesOptions}
        onRow={handleRowChange}
        loading={metaLoading}
        onChange={handleChange}
      />
      <Drawer
        placement="right"
        onClose={() => {
          toggleAddForm(false);
          setCurrentStep(1);
        }}
        visible={addFormVisible}
        width="1100"
        title="New Loan Type"
        destroyOnClose
      >
        {steps.map((item) => (
          <div
            className={`steps-content ${item.step !== currentStep && "hidden"}`}
          >
            {item.component}
          </div>
        ))}
      </Drawer>
      <Drawer
        placement="right"
        onClose={() => toggleSettingsVisibility(false)}
        visible={settingsVisible}
        width="500"
        title="Column Options"
        destroyOnClose
      >
        <ColumnOptions
          defaultColumns={defaultColumns}
          filteredColumns={filteredColumns}
          setFilteredColumns={setFilteredColumns}
          onClose={() => toggleSettingsVisibility(false)}
          setDefaultColumns={setDefaultColumns}
          tableColumns={columns}
          onAction={updateColumn}
          tableKey={ColumnOrder.LOAN_TYPES}
        />
      </Drawer>
    </div>
  );
}

export default LoanTypes;
