export enum ReportNameType {
  DEMAND = "demand",
  REPAYMENT = "repayment",
  OVERDUE = "overdue",
  DISBURSEMENT = "disbursement",
  OUTSTANDING = "outstanding",
  PAYMENT = "payment",
  INSURANCE = "insurance",
  BOOK_DEBT = "book",
  PRECLOSE = "preclose",
}
