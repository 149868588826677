import * as Yup from "yup";

export const VivitriValidationSchema = Yup.object().shape({
  totalOverDueAmount: Yup.number().required(
    "Total overdue amount is required"
  ),

  maxDpdTradeline3Months: Yup.number().required(
    "Maximum DPD tradeline for three months is required"
  ),

  maxDpdTradeline6Months: Yup.number().required(
    "Maximum DPD tradeline for six months is required"
  ),

  maxDpdTradeline12Months: Yup.number().required(
    "Maximum DPD tradeline for twelve months is required"
  ),

  bureauScore: Yup.number().required("Bureau score is required"),

  riskEvents: Yup.number().required("Risk events are required"),

  cbTotalOverDueAmount: Yup.number().required(
    "Co-borrower total overdue amount is required"
  ),

  cbMaxDpdTradeline3Months: Yup.number().required(
    "Co-borrower maximum DPD tradeline for three months is required"
  ),

  cbMaxDpdTradeline6Months: Yup.number().required(
    "Co-borrower maximum DPD tradeline for six months is required"
  ),

  cbMaxDpdTradeline12Months: Yup.number().required(
    "Co-borrower maximum DPD tradeline for twelve months is required"
  ),

  cbBureauScore: Yup.number().required("Co-borrower bureau score is required"),

  cbRiskEvents: Yup.number().required("Co-borrower Risk Events are required"),
});
