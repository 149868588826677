/* eslint-disable react-hooks/exhaustive-deps */
import "./center.scss";
import { Center, CenterParams } from "../../../models/center.model";
import { Drawer, TableColumnsType, TableProps } from "antd";
import React, { useEffect, useState } from "react";
import CenterFilters from "./CenterFilters";
import CenterForm from "./CenterForm";
import { CentersService } from "../../../services/Centers/centers.service";
import { ColumnsType, SorterResult } from "antd/lib/table/interface";
import Table from "../../../shared/components/Table";
import { ToggleDrawer } from "../../../shared/types/toggleDrawer";
import useQueryParams from "../../../shared/hooks/useQueryParams";
import useSorting from "../../../shared/hooks/useSorting";
import { updatedPageOnItemAddition } from "../../../shared/utils/helpers";
import ColumnOptions from "../../../shared/components/ColumnOptions";
import { TableConfig } from "../../../enums/tableConfig.type";
import useTableConfig from "../../../shared/hooks/useTableConfig";
import { Filters } from "../../../models/filters.model";
import FilterButtons from "../../../shared/components/FilterButtons";
import TabExtraContent from "../../../shared/components/TabExtraContent";
import { ColumnOrder } from "../../../enums/columnOrder.enum";
import { getColumnKeys, getColumnTitles, getDefaultColumns, getDeletedColumns, hasColumnDifference } from "../../../shared/utils/columnUtil";
import { ColumnsService } from "../../../services/Columns/columns.service";
import { LocalStorageHelper } from "../../../shared/utils/localStorageHelper";
import { LocalStorage } from "../../../enums/localStorage.enum";

const sortBy = {
  code: "code",
  name: "name",
  branchName: "branch_name",
  isActive: "is_active",
};

interface CentersProps {
  params?: CenterParams;
  addFormVisible: boolean;
  filtersVisible: boolean;
  settingsVisible: boolean;
  toggleAddForm: ToggleDrawer;
  toggleFiltersVisibility: ToggleDrawer;
  toggleSettingsVisibility: ToggleDrawer;
}

const Centers = (props: CentersProps) => {
  const { getCenters, loading } = CentersService();

  const { params, updateParams, getParams, handleSearch: handleCenterSearch } = useQueryParams<CenterParams>({
    params: new CenterParams(),
  });

  const { updateSortData } = useSorting({ sortBy });

  const {
    params: centerParams,
    addFormVisible,
    filtersVisible,
    settingsVisible,
    toggleAddForm,
    toggleFiltersVisibility,
    toggleSettingsVisibility,
  } = props;
  const columns: ColumnsType<Center> = [
    {
      title: "Center ID",
      dataIndex: "code",
      sorter: true,
    },
    {
      title: "Center Name",
      dataIndex: "name",
      sorter: true,
    },
    {
      title: "Branch Name",
      dataIndex: "branchName",
      sorter: true,
    },

    {
      title: "Status",
      dataIndex: "isActive",
      sorter: true,

      render: (isActive: boolean) =>
        isActive ? (
          <span className="active-status text-capitalize text-success">
            active
          </span>
        ) : (
          <span className="active-status text-capitalize text-danger">
            inactive
          </span>
        ),
    },
    // {
    //   width: 90,
    //   render: (isActive: boolean, center: Center, index: number) => (
    //     <span className="branch-actions actions">
    //       <Button
    //         onClick={() => {
    //           toggleAddForm();
    //           setCenterData(center);
    //         }}
    //       >
    //         <img src={editIcon} alt="" width={24} />
    //       </Button>
    //     </span>
    //   ),
    // },
  ];

  const [centers, setCenters] = useState<Center[]>([]);
  const [centerData, ] = useState<Center>();
  const [filters, setFilters] = useState<Filters>(new Filters());
  const [totalPages, setTotalPages] = useState(1);

  const [defaultColumns, setDefaultColumns] =
    useState<TableColumnsType<Center>>(columns);

  const { columnConfig, setColumnConfig, getColumnOrder } = useTableConfig();
  const [filteredColumns, setFilteredColumns] = useState<string[]>(
    columnConfig?.center ?? []
  );

  const addCenter = async (center: Center) => {
    const updatedPage = updatedPageOnItemAddition(totalPages);
    if (params?.page !== updatedPage) {
      updateParams({ page: updatedPage });
    } else {
      setCenters((centers) => [...centers, center]);
      setTotalPages((pages) => ++pages);
    }
  };

  const updateCenter = async (center: Center) => {
    setCenters((prevCenters) =>
      prevCenters.map((c) => (c.id === center.id ? center : c))
    );
  };

  const populateCenters = async (params: CenterParams) => {
    const centersData = await getCenters(params);
    if (centersData?.centers) setCenters(centersData?.centers);
    if (centersData?.meta) setTotalPages(Number(centersData?.meta?.totalCount));
    if (centersData?.meta?.filters) setFilters(centersData?.meta?.filters)
  };

  const handleUpdateParams = () => updateParams(getParams());

  const handleChange: TableProps<Center>["onChange"] = (
    pagination,
    _,
    sorter
  ) => {
    const { sortBy, sortDirection } = updateSortData(
      sorter as SorterResult<Center>
    );
    updateParams({
      page: pagination?.current,
      sortBy,
      sortDirection,
    });
  };

  useEffect(() => {
    const updateParams = centerParams?.searchText
    ? { searchText: params.searchText, page: params.page }
      : params;
    populateCenters(updateParams);
  }, [params, centerParams?.searchText]);

  useEffect(() => {
    setColumnConfig(TableConfig.CENTER, filteredColumns);
  }, [filteredColumns]);

  const setColumns = () => {
    if (originalOrder && originalOrder.length > 0) {
      const updatedColumns = getDefaultColumns(columns, storedColumnOrder, storedColumnKeys, originalOrder);
      setDefaultColumns(updatedColumns);
    } else {
      setDefaultColumns(columns);
    }
  }
  useEffect(() => {
    fetchColumnOrder()
  }, []);

  const fetchColumnOrder = async () => {
    const columnOrder = await getColumns({
      pageName: ColumnOrder.CENTERS,
      employeeId: employee.id
    })
    if (!columnOrder) {
      publishColumns()
    }
    setOriginalOrder(columnOrder?.columnArray ?? [])
    setColumnConfig(ColumnOrder.CENTERS, columnOrder?.columnArray ?? [])
  }

  const setDeletedColumns = () => {
    setFilteredColumns(getColumnTitles(getDeletedColumns(columns, originalOrder)))
  }
  const tableColumnTitles = getColumnTitles(columns)
    const storedColumnOrder = getColumnOrder(ColumnOrder.CENTERS);
    const storedColumnKeys = getColumnKeys(storedColumnOrder)
    const employee = LocalStorageHelper.getItem(LocalStorage.EMPLOYEE);
    const [originalOrder, setOriginalOrder] = useState<string[]>([])
    const { createColumns, getColumns, updateColumns } = ColumnsService()

  const publishColumns = async () => {
    await createColumns({
     pageName: ColumnOrder.CENTERS,
     columnArray: tableColumnTitles,
     employeeId: employee?.id
   })
  }

  useEffect(() => {
    if (hasColumnDifference(columns, defaultColumns)) {
      publishColumns();
    }
  }, []);

  useEffect(() => {
    if (originalOrder && originalOrder.length) {
      setColumns();
      setDeletedColumns()
    }
  }, [originalOrder]);


  const updateColumn = async (columns: ColumnsType<Center>) => {
    await updateColumns({
      columnArray: getColumnTitles(columns),
      pageName: ColumnOrder.CENTERS
    })
  }

  return (
    <div className="centers">
    {!loading && filters && (
      <FilterButtons filters={filters} onFilter={handleUpdateParams}/>
    )}
    <div className="centers-extra-content">
    <TabExtraContent
            searchProps={{
              value: params.searchText,
              onSearch: handleCenterSearch,
              placeholder: "Search Center",
            }}
          />
      </div>
      <Table
        dataSource={centers}
        columns={defaultColumns.filter(
          (col) => !filteredColumns.includes(col.title as string)
        )}
        onChange={handleChange}
        loading={loading}
        pagination={{
          current: params?.page,
          total: totalPages,
        }}
        rowKey={(record) => record?.id}
      />
      <Drawer
        placement="right"
        onClose={() => toggleAddForm(false)}
        visible={addFormVisible}
        width="500"
        title={`${centerData ? `Edit Center` : `New Center`}`}
        destroyOnClose
      >
        <CenterForm
          onAdd={addCenter}
          closeDrawer={toggleAddForm}
          centerData={centerData}
          onUpdate={updateCenter}
        />
      </Drawer>
      <Drawer
        placement="right"
        onClose={() => toggleFiltersVisibility(false)}
        visible={filtersVisible}
        width="918"
        title="Filter Centers"
        destroyOnClose
      >
        <CenterFilters
          onClose={() => toggleFiltersVisibility(false)}
          onFilter={updateParams}
        />
      </Drawer>
      <Drawer
        placement="right"
        onClose={() => toggleSettingsVisibility(false)}
        visible={settingsVisible}
        width="500"
        title="Column Options"
        destroyOnClose
      >
        <ColumnOptions
          defaultColumns={defaultColumns}
          filteredColumns={filteredColumns}
          setFilteredColumns={setFilteredColumns}
          onClose={() => toggleSettingsVisibility(false)}
          setDefaultColumns={setDefaultColumns}
          tableColumns={columns}
          onAction={updateColumn}
          tableKey={ColumnOrder.CENTERS}
        />
      </Drawer>
    </div>
  );
};
export default Centers;
