import { Col, Row } from "antd";
import moment from "moment";
import React from "react";
import { DateFormat } from "../../../../../enums/dateFormat.type";
import { UserRole } from "../../../../../enums/userRoles";
import "./userPictures.scss";
import { Loan } from "../../../../../models/loan.model";
import { dateTimeFormatter } from "../../../../../shared/utils/formatter";
import CustomDocument from "../../../../../shared/components/CustomDocument";
import { LoanType } from "../../../../../enums/loanType.type";

interface UserPicturesProps {
  loan: Loan;
}

interface ImageProps {
  label?: string;
  title?: string;
  imgSrc: string;
  thumbnailSrc?: string;
  name?: string;
  isEmpty?: boolean;
  isDetails?: boolean;
  capturedTime?: string;
}
const UserPictures = (props: UserPicturesProps) => {
  const { loan } = props;

  const images: ImageProps[] = [
    {
      title: "CRO Picture",
      imgSrc: loan?.croPictureUrl ?? "",
      thumbnailSrc: loan?.customer?.thumbnailDocs?.defaultProfileThumb,
      name: loan?.croName || "NA",
      isDetails: true,
      capturedTime: loan?.croCapturedTime
        ? dateTimeFormatter(loan?.croCapturedTime, DateFormat.DATE_MONTH)
        : "NA",
    },
    {
      title: "BM Picture",
      imgSrc: loan?.bmPictureUrl ?? "",
      thumbnailSrc: loan?.customer?.thumbnailDocs?.defaultProfileThumb,
      name: loan?.bmName || "NA",
      isDetails: true,
      capturedTime: loan?.bmCapturedTime
        ? dateTimeFormatter(loan?.bmCapturedTime, DateFormat.DATE_MONTH)
        : "NA",
    },
    {
      title: "CO Picture",
      imgSrc: loan?.iaPictureUrl ?? "",
      thumbnailSrc: loan?.customer?.thumbnailDocs?.defaultProfileThumb,
      isDetails: true,
      name: loan?.coName || "NA",
      capturedTime: loan?.coCapturedTime
        ? moment(loan?.coCapturedTime).format("DD/MM/YYYY hh:mm A")
        : "NA",
    },

    {
      label: "CRO Captured House Pic",
      thumbnailSrc: loan?.customer?.thumbnailDocs?.houseImageThumb,
      imgSrc:
        loan?.customer?.address?.getAddressImageByRole(
          UserRole.CUSTOMER_REPRESENTATIVE
        )?.attachmentUrl ?? "",
      capturedTime: dateTimeFormatter(
        loan?.customer?.address?.getAddressImageByRole(
          UserRole.CUSTOMER_REPRESENTATIVE
        )?.createdAt,
        DateFormat.DATE_MONTH
      ),
    },
    {
      label: "BM Captured House Pic",
      thumbnailSrc: loan?.customer?.thumbnailDocs?.houseImageThumb,
      imgSrc:
        loan?.customer?.address?.getAddressImageByRole(UserRole.BRANCH_MANAGER)
          ?.attachmentUrl ?? "",
      capturedTime: dateTimeFormatter(
        loan?.customer?.address?.getAddressImageByRole(UserRole.BRANCH_MANAGER)
          ?.createdAt,
        DateFormat.DATE_MONTH
      ),
    },
    {
      label: "CO Captured House Pic",
      thumbnailSrc: loan?.customer?.thumbnailDocs?.houseImageThumb,
      imgSrc:
        loan?.customer?.address?.getAddressImageByRole(UserRole.CREDIT_OFFICER)
          ?.attachmentUrl || loan?.customer?.address?.addressAttachment?.[2]?.attachmentUrl || ""
          ,
      capturedTime: dateTimeFormatter(
        loan?.customer?.address?.getAddressImageByRole(UserRole.CREDIT_OFFICER)
          ?.createdAt || loan?.customer?.address?.addressAttachment?.[2]?.createdAt,
        DateFormat.DATE_MONTH
      ),
    },
    ...(loan?.loanTypeCode === LoanType.BUSINESS
      ? [
          {
            label: "CRO Captured Business Picture",
            thumbnailSrc: loan?.customer?.thumbnailDocs?.documentDefaultThumb,
            imgSrc:
              loan?.businessDetail?.address?.getAddressImageByRole(
                UserRole.CUSTOMER_REPRESENTATIVE
              )?.attachmentUrl ?? "",
            capturedTime: dateTimeFormatter(
              loan?.businessDetail?.address?.getAddressImageByRole(
                UserRole.CUSTOMER_REPRESENTATIVE
              )?.createdAt,
              DateFormat.DATE_MONTH
            ),
          },
          {
            label: "BM Captured Business Picture",
            thumbnailSrc: loan?.customer?.thumbnailDocs?.documentDefaultThumb,
            imgSrc:
              loan?.businessDetail?.address?.getAddressImageByRole(
                UserRole.BRANCH_MANAGER
              )?.attachmentUrl ?? "",
            capturedTime: dateTimeFormatter(
              loan?.businessDetail?.address?.getAddressImageByRole(
                UserRole.BRANCH_MANAGER
              )?.createdAt,
              DateFormat.DATE_MONTH
            ),
          },
          {
            label: "CO Captured Business Picture",
            thumbnailSrc: loan?.customer?.thumbnailDocs?.documentDefaultThumb,
            imgSrc:
              loan?.businessDetail?.address?.getAddressImageByRole(
                UserRole.CREDIT_OFFICER
              )?.attachmentUrl || loan?.businessDetail?.address?.addressAttachment?.[2]?.attachmentUrl || "",
            capturedTime: dateTimeFormatter(
              loan?.businessDetail?.address?.getAddressImageByRole(
                UserRole.CREDIT_OFFICER
              )?.createdAt || loan?.businessDetail?.address?.addressAttachment?.[2]?.createdAt,
              DateFormat.DATE_MONTH
            ),
          },
        ]
      : []),
  ];

  return (
    <Row gutter={[16, 16]} className="block block--image-container">
      {images?.map(
        ({ label, imgSrc, isEmpty, name, capturedTime, isDetails, title, thumbnailSrc }) => (
          <Col className="block__image" span={8}>
            {!isEmpty && (
              <>
                <span className="block__image__name">{title}</span>
                <CustomDocument lazyLoad thumbnailSrc={thumbnailSrc} src={imgSrc} />
                <span className="block__image__name">{label}</span>

                <Row className="mb-5">
                  {isDetails && (
                    <>
                      <Col span={8}>Name </Col>
                      <Col span={16} className="text-bold">
                        {name}
                      </Col>
                    </>
                  )}
                  <Col span={8}>Captured Time</Col>
                  <Col span={16} className="text-bold">
                    {capturedTime}
                  </Col>
                </Row>
              </>
            )}
          </Col>
        )
      )}
    </Row>
  );
};

export default UserPictures;
