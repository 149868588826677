export enum ColumnOrder {
    APPROVED_LOANS = "approved_loans",
    PENDING_REQUESTS = "pendings_requests",
    REJECTED_REQUESTS = "rejected_requests",
    BRANCH_ACCOUNTANT = "branch_accountant",
    BRANCHES = "branches",
    CENTERS = "centers",
    LOAN_TYPES = "loan_types",
    DISBURSEMENT_LOANS = "disbursement_loans",
    INDIVIDUAL_CUSTOMERS = "individual_customers",
    CLAIMED_CLAIMS = "claimed_claims",
    INITIATED_CLAIMS = "initiated_claims",
    PROCESSING_CLAIMS = "processing_claims",
    OFFLINE_LOANS = "offline_loans",
    PIPELINE_LOANS = "pipeline_loans",
    REJECTED_LOANS = "rejected_loans",
    USERS = "users",
    LOANS = "loans",
    CT_VERIFICATION = "ct_verification",
    PIPELINE = "pipeline",
    CASH_ON_HAND = "cash_on_hand",
    DEPOSITS = "deposits",
    REPAYMENTS = "repayments",
    PENDING_LOANS = "pending_loans",
    RETURNED_LOANS = "returned_loans",
    STORED_LOANS = "stored_loans",
    TO_RETURN_LOANS = "to_return_loans",
}