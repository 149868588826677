/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Col, Row, Spin } from "antd";
import DashStats from "../DashStats";
import StatsGraph from "../../../shared/components/StatsGraph";
import { AuthContext } from "../../../context/AuthContext";
import { DashboardService } from "../../../services/Dashboard/dashboardService.service";
import { Moment } from "moment";
import "../dashboard.scss";
import { RoleName } from "../../../enums/roleName.enum";
import CustomRangePicker from "../../../shared/components/CustomRangePicker";
import {
  Dashboard as DashboardModel,
  DashboardParams,
} from "../../../models/dashboard.model";
import { Graph } from "../../../enums/Graph.type";
import { getNCharacters } from "../../../shared/utils/getNCharacters";
import { DateRange } from "../../../shared/types/date.type";
import { CardProps } from "../../../shared/types/card.type";
import { User } from "../../../enums/user.type";
import { restrictMonths } from "../../../shared/utils/dateUtils";

interface SuperAdminDashboardProps {
  onRoleChange: (role: User) => void;
  selectedRole?: string;
}

const SuperAdminDashboard = (props: SuperAdminDashboardProps) => {
  const { onRoleChange, selectedRole } = props;
  const { user } = AuthContext();
  const {
    getDashboard,
    getDashboardTAT,
    getSuperAdminGraph,
    loading,
    tatLoading,
  } = DashboardService();
  const currentUserRole = user?.employee?.roleName;
  const [dashboardData, setDashboardData] = useState<DashboardModel>();
  const [dashboardGraph, setDashboardGraph] = useState<DashboardModel>();
  const [filter, setFilter] = useState<DashboardParams>();
  const [tatValue, setTatValue] = useState()

  useEffect(() => {
    handleGetDashboard({ ...filter, user: filter?.user ?? User.SUPER_ADMIN });
  }, [filter]);

  useEffect(() => {
    handleGetDashboardTAT();
    handleDashboardGraph(Graph.OUTSTANDING);
    handleDashboardGraph(Graph.DISBURSE);
  }, []);

  const handleGetDashboard = async (params?: DashboardParams) => {
    const data = await getDashboard(params);
    data && setDashboardData(data);
  };

  const handleGetDashboardTAT = async () => {
    const tatValue = await getDashboardTAT();
    tatValue && setTatValue(tatValue)
  };

  const handleFilter = (value: DashboardParams) => {
    setFilter({ ...filter, ...value });
    onRoleChange(value.user as User);
  };

  const handleDashboardGraph = async (type: Graph, values?: DateRange) => {
    const data = await getSuperAdminGraph({ ...values }, type);

    data &&
      setDashboardGraph((existingData) => ({
        ...existingData,
        [type === Graph.OUTSTANDING ? "outstandingGraph" : "disbursementGraph"]:
          data,
      }));
  };

  const handleGraphData = (
    graphData: "outstandingGraph" | "disbursementGraph"
  ): { key: string; count: number; value: number }[] => {
    const months: string[] = (dashboardGraph?.[graphData]?.map((graph) => String(graph.month)) || [])
    return dashboardData
      ? months.map((key) => ({
        key,
        count:
          (dashboardGraph?.[graphData] || []).find(
            (item) => getNCharacters(String(item?.month), 3) === key
          )?.sum ?? 0,
        value:
          (dashboardGraph?.[graphData] || []).find(
            (item) => getNCharacters(String(item?.month), 3) === key
          )?.count ?? 0,
      }))
      : [];
  };

  const card: CardProps[] = [
    {
      label: "Total Customers",
      count: dashboardData?.totalCustomer,
      value: dashboardData?.totalLoanAmount,
    },
    {
      label: "Average TAT",
      count: tatValue,
      loading: tatLoading,
      key: "tat",
    },
    {
      label: "Total Active Loans",
      count: dashboardData?.totalActiveLoan,
      value: dashboardData?.totalActiveLoanAmount,
    },
    {
      label: "Total Outstanding ",
      count: dashboardData?.totalOutstanding,
      value: dashboardData?.totalOutstandingAmount,
    },
    {
      label: "Total Own Portfolio",
      count: dashboardData?.totalOwnPortfolio,
      value: dashboardData?.totalOwnPortfolioAmount,
    },
    {
      label: "Total Managed Portfolio",
      count: dashboardData?.totalManagedPortfolio,
      value: dashboardData?.totalManagedPortfolioAmount,
    },
  ];

  const restrictMonthsInGraph = (
    current: Moment,
    selectedFromDate: Moment | null,
    maxMonths: number = 11
  ) => {
   return !!selectedFromDate && restrictMonths(current, selectedFromDate, maxMonths);
  };
  
  return (
    <div className="super-admin dashboard">
      {loading && (
        <div className="spin-overlay">
          <Spin />
        </div>
      )}
      <Row gutter={[30, 16]}>
        <Col span={10}>
          <DashStats
            employee={user?.employee}
            card={card}
            onFilter={handleFilter}
            role={selectedRole}
          />
        </Col>
        <Col span={14}>
          {dashboardGraph?.disbursementGraph && (
            <StatsGraph
              title="Loan Outstanding per Month"
              hasCard
              data={handleGraphData("outstandingGraph")}
              axes={{
                x: "Total Loans",
                y: "Total Amount",
              }}
              barColor="#22efb0"
              extraContent={
                <Row gutter={[20, 20]}>
                  <Col span={`${currentUserRole === RoleName.ADMIN ? 24 : 12}`}>
                    <CustomRangePicker
                      onChange={(values) =>
                        handleDashboardGraph(Graph.OUTSTANDING, values)
                      }
                      disabledDateCondition={restrictMonthsInGraph}
                    />
                  </Col>
                </Row>
              }
            />
          )}
          {dashboardGraph?.outstandingGraph && (
            <StatsGraph
              title="Loan Disbursement by Month"
              hasCard
              data={handleGraphData("disbursementGraph")}
              axes={{
                x: "Count",
                y: "Total Amount",
              }}
              barColor="#2276ef"
              extraContent={
                <Row gutter={[20, 20]}>
                  <Col span={24}>
                    <CustomRangePicker
                      onChange={(values) =>
                        handleDashboardGraph(Graph.DISBURSE, values)
                      }
                      disabledDateCondition={restrictMonthsInGraph}
                    />
                  </Col>
                </Row>
              }
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default SuperAdminDashboard;
