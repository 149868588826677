/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import Table from '../../shared/components/Table'
import { columns } from './columns'
import { Drawer, TableProps } from 'antd'
import { Loan, LoanParams } from '../../models/loan.model'
import { LoanService } from '../../services/Loan/loan.service'
import useTableConfig from '../../shared/hooks/useTableConfig'
import { ColumnsType, SorterResult } from 'antd/lib/table/interface'
import useQueryParams from '../../shared/hooks/useQueryParams'
import useSorting from '../../shared/hooks/useSorting'
import { generatePath, useNavigate } from 'react-router-dom'
import * as AppRoutes from "../../routes/routeConstants/appRoutes";
import ColumnOptions from '../../shared/components/ColumnOptions'
import useDrawer from '../../shared/hooks/useDrawer'
import TabExtraContent from '../../shared/components/TabExtraContent'
import './branchAccountant.scss'
import FilterButtons from '../../shared/components/FilterButtons'
import { Filters } from '../../models/filters.model'
import BranchAccountantFilters from './Filters'
import { ColumnOrder } from '../../enums/columnOrder.enum'
import { getColumnKeys, getColumnTitles, getDefaultColumns, getDeletedColumns, hasColumnDifference } from '../../shared/utils/columnUtil'
import { ColumnsService } from '../../services/Columns/columns.service'
import { LocalStorageHelper } from '../../shared/utils/localStorageHelper'
import { LocalStorage } from '../../enums/localStorage.enum'

const sortBy = {
  code: "code",
  name: "customer_name",
  organizationName: "organisation_name",
  currentStatusTag: "current_status_tag",
  customerName: "customer_name",
  loanTypeName: "loan_type_name",
  status: "status",
  branch: "center.branch.name",
  centerName: "center_name",
  createdByFirstName: "created_by.firstname",

};

const BranchAccountant = () => {

  const { columnConfig, setColumnConfig, getColumnOrder } = useTableConfig();
  const [filteredColumns, setFilteredColumns] = useState<string[]>(columnConfig?.branchAccountant ?? []);
  const [loans, setLoans] = useState<Loan[]>([])
  const [filters, setFilters] = useState<Filters>(new Filters());
  const { getLoans, loading } = LoanService()
  const { updateSortData } = useSorting<Loan>({ sortBy });
  const navigate = useNavigate();
  const [loanTotalPage, setLoanTotalPage] = useState(1);

  const {
    params: branchAccountantParams,
    handleSearch,
    updateParams,
    getParams,
  } = useQueryParams<LoanParams>({
    params: new LoanParams(),
  });

  const handleChange: TableProps<Loan>["onChange"] = (
    { current },
    _,
    sorter
  ) => {
    const { sortBy, sortDirection } = updateSortData(
      sorter as SorterResult<Loan>
    );
    updateParams({ ...branchAccountantParams, page: current, sortBy, sortDirection });
  };

  const handleRowChange = (loan: any) => ({
    onClick: () => {
      navigate(
        generatePath(AppRoutes.CT_VERIFICATION_LOAN_DETAILS, {
          customerId: loan?.customer.id,
          loanId: loan?.id
        })
      );
    },
  });

  const fetchLoans = async () => {
    const updatedParams = {
      ...branchAccountantParams,
      searchText: branchAccountantParams.searchText,
    };
    const loans = await getLoans(updatedParams);
    if (loans?.loans) setLoans(loans?.loans);
    if (loans?.meta) {
      setLoanTotalPage(loans?.meta?.totalCount ?? 1);
      loans?.meta?.filters && setFilters(loans?.meta?.filters);
    }
  }

  useEffect(()=> {
    fetchLoans()
  },[branchAccountantParams])


  const setColumns = () => {
    if (originalOrder && originalOrder.length > 0) {
      const updatedColumns = getDefaultColumns(columns, storedColumnOrder, storedColumnKeys, originalOrder);
      setDefaultColumns(updatedColumns);
    } else {
      setDefaultColumns(columns);
    }
  }
  useEffect(() => {
    fetchColumnOrder()
  }, []);

  const fetchColumnOrder = async () => {
    const columnOrder = await getColumns({
      pageName: ColumnOrder.BRANCH_ACCOUNTANT,
      employeeId: employee.id
    })
    if (!columnOrder) {
      publishColumns()
    }
    setOriginalOrder(columnOrder?.columnArray ?? [])
    setColumnConfig(ColumnOrder.BRANCH_ACCOUNTANT, columnOrder?.columnArray ?? [])
  }

  const setDeletedColumns = () => {
    setFilteredColumns(getColumnTitles(getDeletedColumns(columns, originalOrder)))
  }

    const storedColumnOrder = getColumnOrder(ColumnOrder.BRANCH_ACCOUNTANT);
    const storedColumnKeys = getColumnKeys(storedColumnOrder)
    const employee = LocalStorageHelper.getItem(LocalStorage.EMPLOYEE);
    const [originalOrder, setOriginalOrder] = useState<string[]>([])
    const [defaultColumns, setDefaultColumns] = useState(
      getDefaultColumns(columns, storedColumnOrder, storedColumnKeys, originalOrder)
    );
    const tableColumnTitles = getColumnTitles(columns)
    const { createColumns, getColumns, updateColumns } = ColumnsService()

  const publishColumns = async () => {
    await createColumns({
     pageName: ColumnOrder.BRANCH_ACCOUNTANT,
     columnArray: tableColumnTitles,
     employeeId: employee?.id
   })
  }

  useEffect(() => {
    if (hasColumnDifference(columns, defaultColumns)) {
      publishColumns();
    }
  }, []);

  useEffect(() => {
    if (originalOrder && originalOrder.length) {
      setColumns();
      setDeletedColumns()
    }
  }, [originalOrder]);

  const {
    visible: branchAccountantSettingsVisible,
    toggleVisibility: branchAccountantSettingsVisibility,
  } = useDrawer({});

  const {
    visible: branchAccountantFiltersVisible,
    toggleVisibility: branchAccountantFiltersVisibility,
  } = useDrawer({});

  const handleUpdateParams = () => updateParams(getParams())

  const updateColumn = async (columns: ColumnsType<Loan>) => {
    await updateColumns({
      columnArray: getColumnTitles(columns),
      pageName: ColumnOrder.BRANCH_ACCOUNTANT
    })
  }

  return (
    <div className='branch-accountant'>
      <div className="branch-accountant-extra-content">
        <TabExtraContent
          searchProps={{
            onSearch: handleSearch,
            placeholder: "Search Loan",
            value: branchAccountantParams.searchText,
          }}
          filterClick={branchAccountantFiltersVisibility}
          settingsClick={branchAccountantSettingsVisibility}
        />
      </div>
      {!loading && (
        <FilterButtons filters={filters} onFilter={handleUpdateParams} />
      )}
      <Table
        rowKey="id"
        scroll={{ x: true }}
        columns={defaultColumns.filter(
          (col) => !filteredColumns.includes(col.title as string)
        )}
        dataSource={loans}
        loading={loading}
        onRow={handleRowChange}
        showSorterTooltip={false}
        onChange={handleChange}
        pagination={{
          current: branchAccountantParams?.page,
          total: loanTotalPage,
          hideOnSinglePage: true,
        }}
      />
        <Drawer
          placement="right"
          onClose={() => branchAccountantFiltersVisibility(false)}
          visible={branchAccountantFiltersVisible}
          width="916"
          title="Filters"
          closable
          destroyOnClose
        >
          <BranchAccountantFilters
            onClose={() => branchAccountantFiltersVisibility(false)}
            onFilter={updateParams}
          />
        </Drawer>
        <Drawer
          placement="right"
          onClose={() => branchAccountantSettingsVisibility(false)}
          visible={branchAccountantSettingsVisible}
          width={500}
          title="Column Options"
          destroyOnClose
        >
          <ColumnOptions
            defaultColumns={defaultColumns}
            tableColumns={columns}
            filteredColumns={filteredColumns}
            setFilteredColumns={setFilteredColumns}
            onClose={() => branchAccountantSettingsVisibility(false)}
            setDefaultColumns={setDefaultColumns}
            onAction={updateColumn}
            tableKey={ColumnOrder.BRANCH_ACCOUNTANT}
          />
        </Drawer>
    </div>
  )
}

export default BranchAccountant