import React, { useState, useEffect, useRef } from "react";
import { Modal } from "antd";
import "./customDocuments.scss";
import pdfImage from "../../../Assets/images/pdf.png";
import { customDocumentConstants } from "./constants";
import { isPDF } from "../../utils/helpers";
import AppLoader from "../AppLoader";
import previewAvatarImage from "../../Constant/previewAvatarImage";

interface CustomDocumentProps {
  height?: number;
  width?: number;
  src?: string;
  label?: string;
  classname?: string;
  onlyText?: boolean;
  thumbnailSrc?: string;
  lazyLoad?: boolean;
}

const CustomDocument: React.FC<CustomDocumentProps> = ({
  height = 130,
  width = 211,
  src,
  label,
  classname,
  onlyText = false,
  thumbnailSrc,
  lazyLoad = false,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isIntersecting, setIsIntersecting] = useState(!lazyLoad);
  const [isLoading, setIsLoading] = useState(!lazyLoad);
  const imgRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!lazyLoad) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsIntersecting(true);
          observer.unobserve(entry.target);
        }
      },
      { rootMargin: customDocumentConstants.ROOT_MARGIN }
    );

    const currentImgRef = imgRef.current;

    if (currentImgRef) {
      observer.observe(currentImgRef);
    }

    return () => {
      if (currentImgRef) {
        observer.unobserve(currentImgRef);
      }
    };
  }, [lazyLoad]);

  const handleImageClick = () => {
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const handleImageLoad = () => {
    setIsLoading(true);
  };

  const imageSource = isPDF(src) ? pdfImage : thumbnailSrc || src;
  const imageAlt = isPDF(src) ? customDocumentConstants.IMAGE_ALT.PDF : customDocumentConstants.IMAGE_ALT.CUSTOM_IMAGE;

  const pdfProps = isPDF(src)
  ? {
      className: "pdf-modal",
      bodyStyle: { height: 600 },
      width: 800,
    }
  : {};

  return (
    <div className="custom-document">
      {onlyText ? (
        <span className="cursor-pointer" onClick={handleImageClick}>
          {label}
        </span>
      ) : (
        <div ref={imgRef}>
          {isIntersecting && (
            <div className="custom-document__lazy-image">
              <AppLoader loading={!isLoading && lazyLoad}>
                <img
                  className={`${!isLoading ? "custom-document__loading-image" : "loaded-image custom-document__loaded-image"}`}
                  style={{
                    height: `${height}px`,
                    width: `${width}px`,
                  }}
                  src={imageSource ? imageSource : previewAvatarImage}
                  alt={imageAlt}
                  onClick={handleImageClick}
                  loading={lazyLoad ? "lazy" : undefined}
                  onLoad={handleImageLoad}
                />
              </AppLoader>
            </div>
          )}
        </div>
      )}
      {!onlyText && (
        <div className={`text-capitalize mt-5 ml-5 ${classname}`}>{label}</div>
      )}
      {src && (
        <Modal {...pdfProps} open={isModalVisible} onCancel={handleModalClose} footer={null}>
          {isPDF(src) ? (
            <iframe
              className="doc-container__iframe"
              src={src}
              title="pdf-viewer"
            />
          ) : (
            <img className="custom-document__modal-img" src={src} alt="custom document modal" />
          )}
        </Modal>
      )}
    </div>
  );
};

export default CustomDocument;
