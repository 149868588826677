/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Drawer, Modal, Row, Spin, Timeline } from "antd";
import React, { useEffect, useState } from "react";
import { Comments } from "../../../../models/comments.model";
import { Customer } from "../../../../models/customer.model";
import { LoanService } from "../../../../services/Loan/loan.service";
import AppLoader from "../../../../shared/components/AppLoader";
import avatarImage from "../../../../Assets/images/img-fallback.png";
import "./comments.scss";
import moment from "moment";
import { removeUnderscore } from "../../../../shared/utils/removeUnderscore";
import Document from "../Documents/Document";
import { getFileExtension } from "../../../../shared/utils/getFileExtension";
import { DocumentExtensions } from "../../../../enums/DocumentExtensions.type";
import { useLocation, useParams } from "react-router-dom";
import useDrawer from "../../../../shared/hooks/useDrawer";
import CommentForm from "./CommentForm";
import * as AppRoutes from "../../../../routes/routeConstants/appRoutes";
import { LocalStorageHelper } from "../../../../shared/utils/localStorageHelper";
import { LocalStorage } from "../../../../enums/localStorage.enum";
import { Loan } from "../../../../models/loan.model";
import { CommentsConstants } from "./constants";
import { SortDirection } from "../../../../enums/sortDirection.type";

interface CommentProps {
  customer?: Customer;
  id?: number;
  isCustomer?: boolean;
  onSuccess?: (Comment: Comments) => void;
  commentLoanId?: string;
  currentLoan: Loan
  comments: Comments[]
}

const Comment = (props: CommentProps) => {
  const { visible: commentFormVisible, toggleVisibility: toggleCommentForm } =
    useDrawer({});
  const [commentsLoading, setCommentsLoading] = useState(false)
  const location = useLocation();
  const { currentLoan, customer, id, isCustomer } = props;
  const userAccess = LocalStorageHelper.getItem(
    LocalStorage.CURRENT_USER_ACCESS
  );
  const { customerId } = useParams();
  const { getLoans, loading, getLoan } = LoanService();
  const [comments, setComments] = useState<Comments[]>([]);
  const [docModelOpen, setDocModelOpen] = useState(false);
  const [, setDocLoading] = useState(true);
  const [selectedFile, setSelectedFile] = useState("");
  const isCTVerification = location?.pathname.includes(
    AppRoutes.CT_VERIFICATION
  );

  const toggleDocModal = () => setDocModelOpen((visible) => !visible);

  const isImage = (endpoint: string) =>
    getFileExtension(endpoint) === DocumentExtensions.PDF ? false : true;

  const handleFileClick = (url: string) => {
    setSelectedFile(url);
    setDocLoading(true);

    toggleDocModal();
  };

  const initializeLoans = async () => {
    setCommentsLoading(true);
  
    const loans = await getLoans({
      customerId: [Number(customer?.id || id || customerId)],
      sortBy: "created_at",
      sortDirection: SortDirection.ASCEND,
    });
  
    const loanIds = loans?.loans.map((loan) => loan.id) || [];
    let combinedComments: Comments[] = [];
  
    if (isCustomer) {
      for (const loanId of loanIds) {
        const loanDetails = await getLoan(Number(loanId));
        if (loanDetails && loanDetails.comments) {
          combinedComments = [...combinedComments, ...loanDetails.comments];
        }
      }
    } else {
      if (loanIds.length > 0 && loanIds[0]) {
        const loanDetails = await getLoan(loanIds[0]);
        if (loanDetails && loanDetails.comments) {
          combinedComments = [...loanDetails.comments];
        }
      }
    }
  
    setComments(combinedComments);
    setCommentsLoading(false);
  };
  

  useEffect(() => {
    setComments([]);
    initializeLoans();
  }, []);

  return (
    <div className="comments">
      {commentsLoading ? (
        <Spin className="text-center" />
      ) : (
        <Row className="card mb-5" gutter={[0, 50]}>
          <Col span={12} className="block__heading">
            All Comments
          </Col>
          {isCTVerification && (
            <Col span={12} className="text-right">
              {userAccess?.writeAccess && (
                <Button
                  className="loan-disburse-btn ml-5 "
                  onClick={() => toggleCommentForm(true)}
                >
                  ADD COMMENTS
                </Button>
              )}
            </Col>
          )}
          <Col span={24}>
            <Timeline>
              {comments?.length ? (
                comments?.map((val, index) => (
                  <>
                    <Timeline.Item key={index} color="#105f49">
                      <h3 className="text-heading mb-5">
                        {moment(val?.createdAt).format("MMMM Do, YYYY")}
                      </h3>
                      <div className="content-box">
                        <Row className="mb-5">
                          <Col span={6}>
                            <span className="block__label">
                              Submitted by {val?.creatorType}:
                            </span>

                            <Row className="mt-5">
                              <Col span={5}>
                                <img
                                  className="user-img mt-2 "
                                  src={avatarImage}
                                  alt=""
                                />
                              </Col>
                              <Col span={14}>
                                <span className="ml-5  block__value">
                                  {`${val?.creatorFirstname ?? " "} ${val?.creatorLastname ?? " "
                                    }`}
                                </span>
                                <div className="block__label ml-5">
                                  ID:{val?.creatorId}
                                </div>
                              </Col>
                              <div className="mt-5">
                                <span></span>
                              </div>
                            </Row>
                          </Col>
                          {val?.creatorBranch && (
                            <Col span={4}>
                              Branch{" "}
                              <div className="block__value">
                                {val?.creatorBranch}
                              </div>
                            </Col>
                          )}
                          {val?.createdAt && (
                            <Col span={7}>
                              Date{" "}
                              <div className="block__value">
                                {moment(val?.createdAt).format(
                                  "DD-MM-YYYY hh:mm a"
                                )}
                              </div>
                            </Col>
                          )}
                        </Row>
                        <Row>
                          {" "}
                          <Col span={6} className="block__value">
                            {removeUnderscore(val?.step ?? "")}
                          </Col>
                        </Row>
                        <Row className="mt-2 mb-5">
                          {" "}
                          <Col span={24} className="block__label">
                            {val?.description ?? ""}
                          </Col>
                        </Row>
                        {val?.attachment?.s3Key && (
                          <Col span={24}>
                            <Document
                              title={""}
                              onClick={handleFileClick}
                              frontImg={{
                                url: val?.attachment?.s3Key,
                              }}
                            />
                          </Col>
                        )}
                      </div>
                    </Timeline.Item>
                  </>
                ))
              ) : (
                <span className="text-danger">No comments available</span>
              )}
            </Timeline>
          </Col>
          <Modal
            open={docModelOpen}
            cancelButtonProps={{ className: "modal-footer__cancel" }}
            onCancel={toggleDocModal}
            footer={false}
            bodyStyle={{ height: "500px" }}
            width="750px"
            className="documents-modal"
            destroyOnClose
          >
            {isImage(selectedFile) ? (
              <>
                <AppLoader loading={loading} />
                <img
                  style={{ maxWidth: "100%", maxHeight: "100%" }}
                  src={selectedFile}
                  onLoad={() => setDocLoading(false)}
                  alt={CommentsConstants.IMAGE_ALT}
                />
              </>
            ) : (
              <iframe src={selectedFile} title="document" />
            )}
          </Modal>
          <Drawer
            placement="right"
            onClose={() => toggleCommentForm(false)}
            open={commentFormVisible}
            width={600}
            title={"Add Comment"}
            destroyOnClose
          >
            <CommentForm
              loanId={String(currentLoan.id)}
              onClose={() => toggleCommentForm(false)}
              onSuccess={initializeLoans}
            />
          </Drawer>
        </Row>
      )}
    </div>
  );
};

export default Comment;
