import { useState } from "react"
import { ColumnsModel } from "../../models/columns.model"
import axiosInstance from "../../interceptor/axiosInstance"
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes"
import { deserialize, serialize } from "serializr"
import { ColumnServiceConstants } from "./constants"
import Notification from "../../shared/components/Notification"
import { NotificationTypes } from "../../enums/notificationTypes"
import { generatePath } from "react-router-dom"

export const ColumnsService = () => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<Error>()

    const createColumns = async (body: ColumnsModel) => {
        setLoading(true)
        try {
            const serializedBody = serialize(ColumnsModel, body)
            const { data } = await axiosInstance.post(ApiRoutes.GET_EMPLOYEE_PAGES, {
                employee_page: serializedBody
            })
            return data
        } catch (ex) {
            Notification({
                message: ColumnServiceConstants.ADD_ERROR_MESSAGE,
                type: NotificationTypes.ERROR,
            });
            setError(ex as Error);
        } finally {
            setLoading(false);
        }
    }
    const getColumns = async (body: Partial<ColumnsModel>) => {
        setLoading(true)
        try {
            const serializedBody = serialize(ColumnsModel, body)
            const endpoint = generatePath(ApiRoutes.GET_EMPLOYEE_PAGE, {
                pageName: serializedBody.page_name
            })
            const { data } = await axiosInstance.get(endpoint)
            const deserializedData = deserialize(ColumnsModel, data)
            return deserializedData
        } catch (ex) {
            Notification({
                message: ColumnServiceConstants.GET_ERROR_MESSAGE,
                type: NotificationTypes.ERROR,
            });
            setError(ex as Error);
        } finally {
            setLoading(false);
        }
    }
    const updateColumns = async (body: ColumnsModel) => {
        setLoading(true)
        try {
            const serializedBody = serialize(ColumnsModel, body)
            const endpoint = generatePath(ApiRoutes.GET_EMPLOYEE_PAGE, {
                pageName: body.pageName
            })
            await axiosInstance.put(endpoint, {
                column_array: serializedBody.column_array
            })
        } catch (ex) {
            Notification({
                message: ColumnServiceConstants.EDIT_ERROR_MESSAGE,
                type: NotificationTypes.ERROR,
            });
            setError(ex as Error);
        } finally {
            setLoading(false);
        }
    }
    return {
        createColumns,
        getColumns,
        updateColumns,
        loading,
        error
    }
}