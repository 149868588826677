import { alias, list, primitive, serializable } from "serializr";

export class ColumnsModel {
    @serializable(alias("employee_id"))
    employeeId?: number

    @serializable(alias("page_name"))
    pageName?: string

    @serializable(alias("column_array", list(primitive())))
    columnArray: string[] = []

    @serializable
    id?: number

    @serializable(alias("created_at"))
    createdAt?: string

    @serializable(alias("updated_at"))
    updatedAt?: string
}