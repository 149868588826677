export enum BCStatusTags {
LOAN_AGREEMENT_PENDING = "loan_agreement_pending",
LOAN_AGREEMENT_UPLOADED = "loan_agreement_uploaded",
LOAN_AGREEMENT_UPLOAD_IN_PROGRESS = "loan_agreement_upload_in_progress",
MASS_API_IN_PROGRESS = "mas_api_in_progress",
MASS_API_FAILURE = "mas_api_failure",
MASS_REJECTED = "mas_rejected",
UGRO_API_FAILED = "ugro_api_failed",
UGRO_API_SUCCESS = "ugro_api_success",
UGRO_DOCUMENT_UPLOAD_FAILED = "ugro_document_upload_failed",
UGRO_DOCUMENT_UPLOADED_SUCCESSFULLY = "ugro_document_upload_successfully",
API_LOGIN_SUCCESSFUL = "api_login_successful",
FILE_APPROVED_AND_AGREEMENT_PENDING = "file_approved_and_agreement_pending",
AGREEMENT_UPLOADED_AND_CONFIRMED = "agreement_uploaded_and_confirmed",
LOAN_DISBURSEMENT_IN_PROGRESS = "loan_disbursement_in_progress",
LOAN_DISBURSED = "loan_disbursed",
SCHEDULE_UPLOADED_SUCCESSFULLY = "schedule_uploaded_successfully",
SENT_TO_VCL = "sent_to_vcl",
VCL_APPROVED = "vcl_approved",
VCL_REJECTED = "vcl_rejected",
READY_TO_SEND_BACK = "ready_to_send_back",
}
