import { Form, Formik, FormikHelpers } from "formik";
import React, { useState } from "react";
import { LoanAgreement } from "../../../../models/loanAgreement.model";
import { ModService } from "../../../../services/MODOfficer/modofficer.service";
import { DocumentUpload } from "../../../../models/fileAttachment.model";
import { DocumentType } from "../../../../enums/documentType.type";
import CustomModal from "../../../../shared/components/CustomModal";
import AttachmentUpload from "../../../../shared/components/AttachmentUpload";
import { Button, Col } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import { UploadLoanAgreementConstants } from "./constants";
import { Loan } from "../../../../models/loan.model";
import { Buttons } from "../../../../enums/buttons.enum";
import { ButtonType, HTMLButtonType } from "../../../../enums/buttonType";
import { UploadLoanAgreementValidation } from "./validation";

interface UploadLoanAgreementProps {
  visible: boolean;
  toggleVisibility: () => void;
  loan: Loan;
}

const UploadLoanAgreement = (props: UploadLoanAgreementProps) => {
  const { loan, visible, toggleVisibility } = props;

  const { createDocument } = ModService();
  const handleSubmit = async (
    values: DocumentUpload,
    { resetForm }: FormikHelpers<DocumentUpload>
  ) => {
    if (loan?.id) {
    const response = await createDocument(
      {
        frontSideImageId: values.frontSideImageId,
        documentType: DocumentType.LOAN_AGREEMENT,
      },
      loan?.id
    );
    if (response) {
      resetForm();
      toggleVisibility();
    }
  };
}

  const [loading, setLoading] = useState(false);
  return (
    <div>
      <Formik
        validationSchema={UploadLoanAgreementValidation}
        initialValues={new LoanAgreement()}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, isValid, dirty, values, resetForm, errors }) => {

          return (
            <CustomModal
              title={UploadLoanAgreementConstants.TITLE}
              visible={visible}
              onCancel={toggleVisibility}
              isOkButton={false}
              isCancelButton={false}
              okText="Confirm"
              closable={false}
            >
              <Form>
                <Col span={24}>
                  <AttachmentUpload
                    accept={UploadLoanAgreementConstants.ACCEPT}
                    name={UploadLoanAgreementConstants.FIELD}
                    placeholder={UploadLoanAgreementConstants.PLACEHOLDER}
                    setFieldValue={setFieldValue}
                    isLoading={(value) => setLoading(value)}
                  />
                  {values.frontSideImageId && !loading && (
                    <Col className="mt-5" span={8}>
                      <span className="text-primary mr-5">
                        {UploadLoanAgreementConstants.FILE_UPLOADED}
                      </span>
                      <CloseCircleOutlined
                        onClick={() =>
                          setFieldValue(
                            UploadLoanAgreementConstants.FIELD,
                            undefined
                          )
                        }
                      />
                    </Col>
                  )}
                  <Col span={24} className="mt-5">
                    <div className="drawer-footer text-right upload-modal-footer">
                      <Button
                        className="drawer-footer__cancel mr-2"
                        htmlType={HTMLButtonType.RESET}
                        onClick={toggleVisibility}
                      >
                        {Buttons.CANCEL}
                      </Button>
                      <Button
                        htmlType={HTMLButtonType.SUBMIT}
                        disabled={!dirty || !isValid || loading}
                        loading={loading}
                        type={ButtonType.DEFAULT}
                        className="ml-5 mod-upload-button drawer-footer__submit"
                      >
                        {Buttons.UPLOAD}
                      </Button>
                    </div>
                  </Col>
                </Col>
              </Form>
            </CustomModal>
          );
        }}
      </Formik>
    </div>
  );
};

export default UploadLoanAgreement;
