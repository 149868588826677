import { Form, Formik } from 'formik';
import React from 'react';
import InputField from '../../../../shared/components/InputField';
import { InputType } from '../../../../enums/input.type';
import { Button, Col, Row } from 'antd';
import '../customerDetail.scss';
import { placeholder, VivitriBorrowerFields, VivitriCoBorrowerFields, vivtriSectionTitles } from './constants';
import { VivitriValidationSchema } from './validation';
import { VivitriBorrowerForm } from '../../../../models/vivitri.model';
import { ButtonType, HTMLButtonType } from '../../../../enums/buttonType';

export interface VivitriFormProps {
    handleSubmit: (values: VivitriBorrowerForm) => void;
    toggleStatusVisibility: (state: boolean) => void;
    loading?: boolean;
}

const SectionFields = ({ sectionTitle, fields }: { sectionTitle: string, fields: typeof VivitriBorrowerFields }) => (
    <>
        <Col className="text-primary" span={24}>
            <h2>{sectionTitle}</h2>
        </Col>
        {fields.map((field) => (
            <Col span={12} key={field.name}>
                <InputField
                    label={field.label}
                    name={field.name}
                    type={InputType.NUMBER}
                    placeholder={`${placeholder} ${field.label}`}
                />
            </Col>
        ))}
    </>
);

const VivitriForm = (props: VivitriFormProps) => {
    const { handleSubmit, toggleStatusVisibility, loading } = props;

    const closeModal = () => {
        toggleStatusVisibility(false)
    }
    
    return (

        <Formik
            initialValues={new VivitriBorrowerForm()}
            onSubmit={handleSubmit}
            validationSchema={VivitriValidationSchema}
            validateOnChange={false}
        >
            {({ dirty, isValid }) => {

                return (
                    <div>
                        <Form className='ant-form-vertical'>
                            <Row gutter={20}>
                                <SectionFields sectionTitle={vivtriSectionTitles.BORROWER} fields={VivitriBorrowerFields} />
                                <SectionFields sectionTitle={vivtriSectionTitles.CO_BORROWER} fields={VivitriCoBorrowerFields} />
                            </Row>
                            <div className="text-right mt-5 drawer-footer">
                                <Button
                                    htmlType={HTMLButtonType.RESET}
                                    onClick={closeModal}
                                    className="modal-footer__cancel"
                                    type={ButtonType.LINK}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    loading={loading}
                                    className="modal-footer__submit w-10"
                                    htmlType={HTMLButtonType.SUBMIT}
                                    type={ButtonType.PRIMARY}
                                    disabled={!dirty || !isValid}
                                >
                                    Confirm
                                </Button>
                            </div>
                        </Form>
                    </div>
                );
            }}
        </Formik>
    )
};

export default VivitriForm;
