/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { TabsProps } from "../../../shared/types/Tabs.type";
import RejectedLoans from "../RejectedLoans";
import { Modal, Tabs } from "antd";
import useQueryParams from "../../../shared/hooks/useQueryParams";
import { LoanParams } from "../../../models/loan.model";
import OfflineLoans from "../OfflineLoans";
import PipelineLoans from "../PipelineLoans";
import PipelineLoanUpload from "../PipelineLoanUpload";
import "../PipelineLoans/pipelineLoans.scss";
import '../pipeline.scss'

type Props = {};

const PipelineWrapper = (props: Props) => {

  const { updateParams } =
    useQueryParams<LoanParams>({
      params: new LoanParams(),
    });

  const location = useLocation()

  useEffect(()=> {
    if (!location.hash) {
      window.location.hash = tabs?.find((tab) => tab.key === activeTab)?.hash ?? '';
    }
  },[location.hash])

  const [activeTab, setActiveTab] = useState("1");
  const [isVisible, setIsVisible] = useState(false);

  const handleModalVisibility = () => setIsVisible(!isVisible);

  const tabs: TabsProps[] = [
    {
        label: "Pipeline Loans",
        key: "1",
        component: (
          <PipelineLoans
          />
        ),
        hash: "#pipeline-loans",
      },
      {
        label: "Imported Loans",
        key: "2",
        component:<OfflineLoans
        />,
        hash: "#imported-loans",
      },
    {
      label: "Rejected Loans",
      key: "3",
      component: <RejectedLoans 
      />,
      hash: "#rejected-loans",
    },
    
  ];

  const tabDetails = tabs?.find((tab) => location?.hash === tab?.hash)
  const tabKey = String(tabDetails?.key)

  const handleTabChange = (activeKey: string) => {
    const hash = tabs?.find((tab, index) => tab?.key === activeKey)?.hash;
    updateParams({ page: 1 }, hash);
    setActiveTab(activeKey);
  };

  return (
    <div className="pipeline-wrapper">
      <Tabs
        activeKey={tabKey !== undefined ? tabKey : "1"}
        onChange={handleTabChange}
      >
        {tabs.map(({ component, ...tab }) => (
          <Tabs.TabPane tab={tab.label} key={tab.key}>
            {component}
          </Tabs.TabPane>
        ))}
      </Tabs>
      <Modal
        visible={isVisible}
        onCancel={handleModalVisibility}
        destroyOnClose
        centered
        footer={false}
        className="pipeline-modal"
      >
        <PipelineLoanUpload handleModalVisibility={handleModalVisibility} />
      </Modal>
    </div>
  );
};

export default PipelineWrapper;
