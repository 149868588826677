import React from "react";
import "./customModal.scss";
import { Modal, Button, ButtonProps } from "antd";

type ModalProps = {
  title: string;
  visible: boolean;
  onCancel?: () => void;
  onOk?: () => void;
  onClose?: () => void;
  className?: string;
  okText?: string;
  showCancel?: boolean;
  cancelText?: string;
  isCancelButton?: boolean;
  isOkButton?: boolean;
  loading?: boolean;
  okButtonProps?: ButtonProps
  closable?: boolean;
  width?: number;
};

const CustomModal: React.FC<ModalProps> = ({
  title,
  visible,
  onCancel = () => {},
  onOk = () => {},
  onClose,
  className,
  okText = "OK",
  showCancel = true,
  cancelText = "Cancel",
  children,
  isCancelButton = true,
  isOkButton = true,
  loading = false,
  okButtonProps,
  closable = true,
  width,
}) => {
  return (
    <Modal
      width={width}
      destroyOnClose
      className={`custom-modal ${className}`}
      title={<h1>{title}</h1>}
      open={visible}
      okButtonProps={okButtonProps}
      closable={closable}
      onCancel={onClose ? onClose : onCancel}
      footer={[
        isCancelButton && (
          <Button key="cancel" onClick={onCancel}>
            {cancelText}
          </Button>
        ),
        isOkButton && (
          <Button key="ok" type="primary" onClick={onOk} loading={loading}>
            {okText}
          </Button>
        ),
      ]}
      maskClosable={false}
    >
      <div className="custom-modal__content">{children}</div>
    </Modal>
  );
};

export default CustomModal;
