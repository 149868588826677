/* eslint-disable react-hooks/exhaustive-deps */
import "./loans.scss";
import * as AppRoutes from "../../routes/routeConstants/appRoutes";
import {
  Button,
  Drawer,
  Modal,
  Popover,
  TableColumnsType,
  TableProps,
} from "antd";
import { Loan, Loan as LoanModel, LoanParams } from "../../models/loan.model";
import { generatePath, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import ChangeStatus from "./ChangeStatus";
import ColumnOptions from "../../shared/components/ColumnOptions";
import { ColumnProps } from "antd/lib/table";
import LoanFilters from "./LoanFilters";
import { LoanService } from "../../services/Loan/loan.service";
import { LoanStatus } from "../../enums/loanStatus.type";
import { ColumnsType, SorterResult } from "antd/lib/table/interface";
import TabExtraContent from "../../shared/components/TabExtraContent";
import Table from "../../shared/components/Table";
import { getFullName } from "../../shared/utils/getFullName";
import useDrawer from "../../shared/hooks/useDrawer";
import useQueryParams from "../../shared/hooks/useQueryParams";
import useSorting from "../../shared/hooks/useSorting";
import { hasAccessOf } from "../../shared/utils/hasAccess";
import { RoleName } from "../../enums/roleName.enum";
import { useLocation } from "react-router";
import moment from "moment";
import { removeUnderscore } from "../../shared/utils/removeUnderscore";
import useTableConfig from "../../shared/hooks/useTableConfig";
import { TableConfig } from "../../enums/tableConfig.type";
import {
  dateTimeFormatter,
} from "../../shared/utils/formatter";
import { DateFormat } from "../../enums/dateFormat.type";
import dotIcon from "../../Assets/images/dotIcon.png";
import { LoanSeedingType } from "../../shared/types/loanSeeding.type";
import { LoanSeeding } from "../../enums/LoanSeeding.type";
import {
  TransferLoans as transferLoanModel,
  TransferLoansParams,
} from "../../models/transferLoans.model";
import TransferLoans from "./TransferLoans";
import { Filters } from "../../models/filters.model";
import FilterButtons from "../../shared/components/FilterButtons";
import { DateRange } from "../../shared/types/date.type";
import CustomRangePicker from "../../shared/components/CustomRangePicker";
import { fillEmptyData } from "../../shared/utils/fillEmptyData";
import { LocalStorageHelper } from "../../shared/utils/localStorageHelper";
import { LocalStorage } from "../../enums/localStorage.enum";
import LoanAmountDetails from "../../shared/components/LoanDetails";
import { AllLoanTypes } from "../../enums/pipelineLoanTypes";
import BookDebtDrawer from "./BookDebt";
import BookDebtModal from "./BookDebt/BookDebtModal";
import { renderModStatus } from "../../shared/utils/renderModStatus";
import { ColumnOrder } from "../../enums/columnOrder.enum";
import { getColumnKeys, getColumnTitles, getDefaultColumns, getDeletedColumns, hasColumnDifference } from "../../shared/utils/columnUtil";
import { ColumnsService } from "../../services/Columns/columns.service";

const sortBy = {
  code: "code",
  customerName: "name",
  name: "customer_name",
  centerName: "center_name",
  createdByFirstName: "created_by.firstname",
  loanTypeName: "loan_type_name",
  status: "status",
  securedType: "secured_type",
  organizationName: "organisation_name",
};

interface ColumnProp extends ColumnProps<Loan> {
  hidden?: Boolean;
}

const Loans = () => {
  const [selectedLoans, setSelectedLoans] = useState<number[]>([])
  const [transferLoans, setTransferLoans] = useState<TransferLoansParams>();
  const [isTransferLoan, setIsTransferLoan] = useState(false);
  const [dateRange, setDateRange] = useState<DateRange>();
  const location = useLocation();
  const userAccess = LocalStorageHelper.getItem(
    LocalStorage.CURRENT_USER_ACCESS
  );
  const isLoanSection = location?.pathname === AppRoutes.LOANS;
  const isPipeline = location?.pathname === AppRoutes.PIPELINE;
  const isCTVerification = location?.pathname === AppRoutes.CT_VERIFICATION;

  const [selectedLoanOption, setSelectedLoanOption] =
    useState<LoanSeedingType>();
  const {
    visible: loanSeedingVisible,
    toggleVisibility: toggleLoanSeedingForm,
  } = useDrawer({});

  const HOAccess =
    hasAccessOf(RoleName.HEAD_OFFICER) &&
    location?.pathname === AppRoutes.CT_VERIFICATION;

  const superAdminAcess =
    hasAccessOf(RoleName.SUPER_ADMIN) &&
    location?.pathname === AppRoutes.CT_VERIFICATION;
  const superAdminAndHOAccess =
    location?.pathname === AppRoutes.CT_VERIFICATION;

  const { getLoans, loading, transferLoan } = LoanService();

  const {
    visible: isChangeStatusVisible,
    toggleVisibility: toggleChangeStatusVisible,
  } = useDrawer({});

  const { visible: loanFilterDrawer, toggleVisibility: toggleFilter } =
    useDrawer({});

  const { visible: loanSetting, toggleVisibility: toggleSettings } = useDrawer(
    {}
  );
  const { params, handleSearch, updateParams, getParams } = useQueryParams({
    params: new LoanParams(),
  });
  const { updateSortData } = useSorting<Loan>({ sortBy });

  const navigate = useNavigate();

  const [loans, setLoans] = useState<LoanModel[]>([]);
  const [selectedTransferedLoan, setSelectedTransferedLoan] = useState<
    transferLoanModel[]
  >([]);
  const [selectedLoan, setSelectedLoan] = useState(new Loan());
  const [loanTotalPage, setLoanTotalPage] = useState(1);
  const [isPopupVisible, setIsPopoupVisible] = useState(false);
  const { columnConfig, setColumnConfig, getColumnOrder } = useTableConfig();
  const [filters, setFilters] = useState<Filters>(new Filters());
  const [isLoading, setIsLoading] = useState(true);
  const [rowSelectionEnabled, setRowSelectionEnabled] = useState(false)
  const columns: ColumnProp[] = [
    {
      title: "Loan ID",
      dataIndex: "code",
      render: (code: string) => code?.toUpperCase(),
      sorter: true,
    },
    {
      title: "Partner ID",
      dataIndex: "laf",
      render: (laf: string) => fillEmptyData(laf?.toUpperCase()),
    },
    {
      title: "Customer Name",
      dataIndex: "name",
      render: (_: string, record: Loan) => record?.customer?.getName(),
      sorter: true,
    },
    {
      title: "Security",
      dataIndex: "securedType",
      render: (security: string) => renderModStatus(security),
    },
    !superAdminAndHOAccess ? {
      title: "MOD Document ID",
      dataIndex: "modDocumentId",
      render: (record: Loan) => record?.modDocumentId
    } : {},
    {
      title: "Branch",
      render: (code: string, loan: Loan) => loan?.branch?.name,
    },
    {
      title: "Center",
      dataIndex: "centerName",
      sorter: true,
    },
    {
      title: "CRO",
      dataIndex: "createdByFirstName",
      sorter: true,
      render: (_: string, record: Loan) => getFullName(record?.createdBy),
    },
    {
      title: "Loan Type",
      dataIndex: "loanTypeName",
      sorter: true,
    },

    {
      title: "Time",
      dataIndex: "time",
      render: (_: string, record: Loan) =>
        record?.submittedAt
          ? moment(record?.submittedAt).format("hh:mm a")
          : " ",
      hidden: true,
    },
    {
      title: "Total Amount",
      dataIndex: "requestedLoanAmt",
    },
    {
      title: "Applied Date",
      dataIndex: "appliedDate",
      render: (appliedDate: string) =>
        dateTimeFormatter(appliedDate, DateFormat.DATE),
    },
    {
      title: "Submitted Date",
      dataIndex: "submittedAt",
      render: (submittedAt: string) =>
        dateTimeFormatter(submittedAt, DateFormat.DATE),
    },
    {
      title: "Approved Date",
      dataIndex: "approvedDate",
      render: (approvedDate: string) =>
        dateTimeFormatter(approvedDate, DateFormat.DATE),
    },

    {
      title: "Disbursed Date",
      dataIndex: "assignedAt",
      render: (_: string, record: Loan) =>
        record?.recentLoanStatus?.assignedAt
          ? moment(record?.recentLoanStatus?.assignedAt).format("DD/MM/YYYY")
          : " ",
      hidden: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      ...(superAdminAndHOAccess ? { sorter: false } : { sorter: true }),
      render: (_: string, record: Loan) => {
        const className =
          "active-status--" + record.status?.split("_")?.join("-");
        return (
          <span
            className={`text-success text-capitalize active-status ${className}`}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setSelectedLoan(record);
              if (
                record?.status === LoanStatus.CT_VERIFICATION ||
                record?.status === LoanStatus.APPROVED
              )
                toggleChangeStatusVisible();
            }}
          >
            {removeUnderscore(record.getLoanStatusLabel() ?? "")}
          </span>
        );
      },
    },

    {
      title: "Preferred channel",
      dataIndex: "organizationName",
      sorter: true,
    },
  ].filter((col) => {
    return HOAccess
      ? col?.dataIndex !== "assignedAt" && col?.dataIndex !== "approvedDate"
      : isLoanSection
        ? col?.dataIndex !== "time" &&
        col?.dataIndex !== "appliedDate" &&
        col?.dataIndex !== "submittedDate" &&
        col?.dataIndex !== "batchId" &&
        col?.dataIndex !== "branchName"
        : !col?.hidden;
  });

  const transferLoanColumn: ColumnProp[] = [
    {
      title: "Loan Id",
      dataIndex: "code",
    },
    {
      title: "Partner ID",
      dataIndex: "laf",
      render: (laf: string) => fillEmptyData(laf?.toUpperCase()),
    },
    {
      title: "Center",
      dataIndex: "center",
    },
    {
      title: "CRO",
      dataIndex: "cro",
    },
    {
      title: "Loan Type",
      dataIndex: "loanType",
    },
    {
      title: "Total Amount",
      dataIndex: "totalAmount",
    },
    {
      title: "Channel",
      dataIndex: "channel",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status) => {
        const className = "active-status--" + status?.split("_")?.join("-");
        return (
          <span
            className={`text-success text-capitalize active-status ${className}`}
          >
            {removeUnderscore(status ?? "")}
          </span>
        );
      },
    },
  ];

  const { visible: isBookDebtVisible, toggleVisibility: toggleBookDebtVisible } = useDrawer({});
  const { visible: isBookDebtModal, toggleVisibility: toggleBookDebtModal } = useDrawer({});

  const toggleBookDebtDrawer = () => {
    if (bookDebtStep === 0)
      toggleBookDebtVisible(!isBookDebtVisible)
    else if (bookDebtStep === 1)
      toggleBookDebtModal(!isBookDebtModal)
  }

  const [defaultColumns, setDefaultColumns] =
    useState<TableColumnsType<Loan>>(columns);
  const [bookDebtStep, setBookDebtStep] = useState(0)
  const [filteredColumns, setFilteredColumns] = useState<string[]>([]);

  const handleRowClick = (loan: LoanModel) => {
    HOAccess || superAdminAcess
      ? navigate(
        generatePath(AppRoutes.CT_VERIFICATION_DETAILS, {
          customerId: loan.customer?.id ? String(loan.customer?.id) : "",
        }),
        { state: { loanId: loan.id ? String(loan.id) : "" } }
      )
      : navigate(
        generatePath(AppRoutes.LOAN_INDIVIDUAL_DETAIL, {
          loanId: loan.id ? String(loan.id) : "",
        }),
        {
          state: {
            disbursedDate: loan?.recentLoanStatus?.assignedAt,
          },
        }
      );
  };
  const handleRowOperation = (record: LoanModel, rowIndex?: number) => ({
    onClick: () => handleRowClick(record),
  });

  const populateLoans = async (loanParams?: LoanParams) => {
    const loanDetails = await getLoans(
      isCTVerification
        ? {
          ...params,
          ...loanParams,
          ...(isLoanSection
            ? { disbursedFromDate: dateRange?.fromDate, disbursedToDate: dateRange?.toDate }
            : { submittedFromDate: params?.submittedFromDate, submittedToDate: params?.submittedToDate }),
          ctVerification: true
        }
        : isLoanSection
          ? { ...params, isHypothecator: params?.isHypothecator, ...loanParams, disbursed: true }
          : params
    );
    if (loanDetails?.loans) setLoans(loanDetails?.loans);
    if (loanDetails?.meta) {
      setLoanTotalPage(loanDetails?.meta?.totalCount ?? 1);
      loanDetails?.meta?.filters && setFilters(loanDetails?.meta?.filters);
      setIsLoading(false)
    }

  };


  useEffect(() => {
    populateLoans()
  }, [params, params.searchText, dateRange])

  useEffect(() => {
    if ('isHypothecator' in params) {
      setBookDebtStep(1)
      setRowSelectionEnabled(true)
    }
    else if (!('isHypothecator' in params)) {
      setRowSelectionEnabled(false)
      setBookDebtStep(0)
    }
  }, [params])

  const handleLoanUpdate = (loan: Loan) =>
    setLoans((loans) => {
      const modifiedLoanIndex = loans.findIndex(
        (existingLoan) => existingLoan.id === loan.id
      );
      loans[modifiedLoanIndex] = loan;
      return [...loans];
    });
  const triggerRowSelection = () => setRowSelectionEnabled(true)
  const handleChange: TableProps<LoanModel>["onChange"] = (
    { current },
    _,
    sorter
  ) => {
    const { sortBy, sortDirection } = updateSortData(
      sorter as SorterResult<Loan>
    );
    updateParams({ ...params, page: current, sortBy, sortDirection });
  };

  const initializeColumn = () => {
    const column = isLoanSection
      ? columnConfig?.loans
      : isPipeline
        ? columnConfig?.pipeline
        : columnConfig?.ctVerification;
    setFilteredColumns(column ?? []);
  };

  useEffect(() => {
    const config = isLoanSection
      ? TableConfig.LOANS
      : isPipeline
        ? TableConfig.PIPELINE
        : TableConfig.CT_VERIFICATION;
    setColumnConfig(config, filteredColumns);
  }, [filteredColumns]);

  useEffect(() => {
    initializeColumn();
  }, []);

  const fetchLoans = () => {
    updateParams(new LoanParams())
    setBookDebtStep(0)
  }

  const handleSelectedLoans = (loans: number[]) => setSelectedLoans(loans);

  const rowSelection = {
    selectedRowKeys: selectedLoans,

    onChange: (selectedRowKeys: React.Key[], loans: Loan[]) => {
      const paymentIntentIds = loans.map((loans) => loans?.id ?? 0);

      handleSelectedLoans(paymentIntentIds);
    },
  };

  const handleOnClose = () => toggleLoanSeedingForm(false);
  const handleOnSuccess = (
    loans: transferLoanModel[],
    transferLoans: TransferLoansParams
  ) => {
    setSelectedTransferedLoan(loans);
    setTransferLoans(transferLoans);

    toggleLoanSeedingForm(false);
    setIsTransferLoan(true);
  };

  const handleTransferLoans = async () => {
    const result = await transferLoan({
      ...new TransferLoansParams(),
      toOrg: transferLoans?.toOrg,
      fromOrg: transferLoans?.fromOrg,
      loanIds: selectedLoans,
    });
    if (result) {
      setIsTransferLoan(false);
      populateLoans();
    }
  };

  const handleUpdateParams = () => updateParams(getParams());
  const handleDateChange = ({ toDate, fromDate, ctVerifiedFromDate, ctVerifiedToDate }: DateRange) => {
    if (isCTVerification) {
      setDateRange({ ctVerifiedFromDate: ctVerifiedFromDate, ctVerifiedToDate: ctVerifiedToDate });
      updateParams({ ...params, ctVerifiedFromDate: ctVerifiedFromDate, ctVerifiedToDate: ctVerifiedToDate })
    }
    else {
      updateParams({ ...params, toDate, fromDate });
    }
  };

  const loanOptions = [
    {
      label: "Transfer Loans",
      value: LoanSeeding.TRANSFER_LOANS,
      component: (
        <TransferLoans
          loans={loans}
          onClose={handleOnClose}
          onSuccess={handleOnSuccess}
        />
      ),
    },
  ];

  const loanColumnOrder = isLoanSection ? ColumnOrder.LOANS : isPipeline ? ColumnOrder.PIPELINE : ColumnOrder.CT_VERIFICATION

  const setColumns = () => {
    if (originalOrder && originalOrder.length > 0) {
      const updatedColumns = getDefaultColumns(columns, storedColumnOrder, storedColumnKeys, originalOrder);
      setDefaultColumns(updatedColumns);
    } else {
      setDefaultColumns(columns);
    }
  }
  useEffect(() => {
    fetchColumnOrder()
  }, []);

  const fetchColumnOrder = async () => {
    const columnOrder = await getColumns({
      pageName: loanColumnOrder,
      employeeId: employee.id
    })
    if (!columnOrder) {
      publishColumns()
    }
    setOriginalOrder(columnOrder?.columnArray ?? [])
    setColumnConfig(loanColumnOrder, columnOrder?.columnArray ?? [])
  }

  const setDeletedColumns = () => {
    setFilteredColumns(getColumnTitles(getDeletedColumns(columns, originalOrder)))
  }
  const tableColumnTitles = getColumnTitles(columns)
  const storedColumnOrder = getColumnOrder(loanColumnOrder);
  const storedColumnKeys = getColumnKeys(storedColumnOrder)
  const employee = LocalStorageHelper.getItem(LocalStorage.EMPLOYEE);
  const [originalOrder, setOriginalOrder] = useState<string[]>([])
  const { createColumns, getColumns, updateColumns } = ColumnsService()

  const publishColumns = async () => {
    await createColumns({
      pageName: loanColumnOrder,
      columnArray: tableColumnTitles,
      employeeId: employee?.id
    })
  }

  useEffect(() => {
    if (hasColumnDifference(columns, defaultColumns)) {
      publishColumns();
    }
  }, []);

  useEffect(() => {
    if (originalOrder && originalOrder.length) {
      setColumns();
      setDeletedColumns()
    }
  }, [originalOrder]);

  const updateColumn = async (columns: ColumnsType<Loan>) => {
    await updateColumns({
      columnArray: getColumnTitles(columns),
      pageName: loanColumnOrder
    })
  }


  return (
    <div className="loan">
      {!isCTVerification &&
        <div className="loan__book-debt-container">
          <Button
            onClick={toggleBookDebtDrawer}
            disabled={!selectedLoans.length && bookDebtStep === 1}
            className="loan-disburse-btn ml-2 mr-2"
          >
            {bookDebtStep === 0 ? "Select Loans for Book Debt" : "Generate Book Debt Report"}
          </Button>
        </div>
      }
      <div className="loan__header">
        {!isTransferLoan ? (
          <>
            <div className="loan__details-container">
              {!isCTVerification && (
                <LoanAmountDetails
                  params={params}
                  type={AllLoanTypes.LOAN_SECTION}
                />
              )}
            </div>
            <div className="d-flex">
              <CustomRangePicker
                className="mr-5 custom-range-picker"
                onChange={handleDateChange}
                ctVerification={isCTVerification}
                values={
                  isCTVerification
                    ? {
                      toDate: params?.ctVerifiedToDate,
                      fromDate: params?.ctVerifiedFromDate,
                    }
                    : {
                      toDate: params?.toDate,
                      fromDate: params?.fromDate,
                    }
                }
              />
              <TabExtraContent
                searchProps={{
                  placeholder: "Search Loan",
                  value: params.searchText ?? "",
                  onSearch: handleSearch,
                }}
                filterClick={toggleFilter}
                settingsClick={toggleSettings}
              />
            </div>
            {isLoanSection && userAccess?.writeAccess && (
              <Popover
                className="ml-5"
                open={isPopupVisible}
                placement="bottomLeft"
                content={
                  <div>
                    {loanOptions?.map((loan) => (
                      <div
                        key={loan?.label}
                        className="cursor-pointer mb-2 "
                        onClick={() => {
                          setSelectedLoanOption(loan);
                          toggleLoanSeedingForm();
                          setIsPopoupVisible(false);
                        }}
                      >
                        {loan?.label}
                      </div>
                    ))}
                  </div>
                }
                trigger="click"
              >
                <a href="#/">
                  <img
                    alt="popover-icon"
                    src={dotIcon}
                    className="dot-icon icon-container"
                    onClick={() => setIsPopoupVisible(!isPopupVisible)}
                  />
                </a>
              </Popover>
            )}
          </>
        ) : (
          <Button
            disabled={!selectedLoans?.length}
            className="loan-disburse-btn"
            onClick={handleTransferLoans}
          >
            Confirm Transfer Loans
          </Button>
        )}
      </div>
      {filters && !isLoading && (
        <FilterButtons filters={filters} onFilter={handleUpdateParams} />
      )}
      {
        <Table
          rowKey="id"
          scroll={{ x: true }}
          columns={
            isTransferLoan
              ? transferLoanColumn
              : defaultColumns.filter(
                (col) => !filteredColumns.includes(col.title as string)
              )
          }
          dataSource={isTransferLoan ? selectedTransferedLoan : loans}
          loading={loading}
          showSorterTooltip={false}
          onRow={handleRowOperation}
          onChange={handleChange}
          pagination={{ current: params.page, total: loanTotalPage }}
          {
          ...((isTransferLoan || (!isCTVerification && rowSelectionEnabled)) && {
            rowSelection: {
              preserveSelectedRowKeys: true,
              type: 'checkbox',
              ...rowSelection,
            },
          })}
        />
      }
      <Drawer
        placement="right"
        visible={loanSetting}
        destroyOnClose
        onClose={() => toggleSettings(false)}
        title="Column Options"
        width={500}
      >
        <ColumnOptions
          defaultColumns={defaultColumns}
          filteredColumns={filteredColumns}
          setFilteredColumns={setFilteredColumns}
          onClose={() => toggleSettings(false)}
          setDefaultColumns={setDefaultColumns}
          tableColumns={columns}
          onAction={updateColumn}
          tableKey={loanColumnOrder}
        />
      </Drawer>
      <Drawer
        placement="right"
        visible={loanFilterDrawer}
        destroyOnClose
        onClose={() => toggleFilter(false)}
        title="Filters"
        width={"70vw"}
      >
        <LoanFilters onFilter={updateParams} onClose={toggleFilter} />
      </Drawer>
      <Modal
        visible={isChangeStatusVisible}
        onCancel={() => toggleChangeStatusVisible(false)}
        cancelButtonProps={{ className: "modal-footer__cancel" }}
        destroyOnClose
        centered
        footer={false}
      >
        <ChangeStatus
          onLoanUpdate={handleLoanUpdate}
          loan={selectedLoan}
          onClose={() => toggleChangeStatusVisible(false)}
        />
      </Modal>
      <Drawer
        placement="right"
        onClose={() => toggleLoanSeedingForm(false)}
        open={loanSeedingVisible}
        width={600}
        title={selectedLoanOption?.label}
        destroyOnClose
      >
        {selectedLoanOption?.component}
      </Drawer>
      <Drawer
        placement="right"
        open={isBookDebtVisible}
        destroyOnClose
        onClose={toggleBookDebtDrawer}
        title="Book Debt"
        width={"604"}
      >
        <BookDebtDrawer
          triggerRowSelection={triggerRowSelection}
          onSuccess={(formValues: LoanParams) => {
            updateParams(formValues)
            setBookDebtStep(0);
          }}
          setBookDebtStep={(step: number) => {
            setBookDebtStep(step)
          }}
          setSelectedLoans={setSelectedLoans}
          toggleDrawer={toggleBookDebtDrawer}
        />
      </Drawer>
      <BookDebtModal setSelectedLoans={setSelectedLoans} fetchLoans={fetchLoans} selectedLoans={selectedLoans} toggleBookDebtModal={toggleBookDebtModal} bookDebtModalVisible={isBookDebtModal} />
    </div>
  );
};

export default Loans;
