/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Table from "../../../shared/components/Table";
import { Button, Col, Drawer, TableColumnsType, TableProps } from "antd";
import { Loan, LoanParams } from "../../../models/loan.model";
import useTableConfig from "../../../shared/hooks/useTableConfig";
import { ColumnsType, SorterResult } from "antd/lib/table/interface";
import useQueryParams from "../../../shared/hooks/useQueryParams";
import useSorting from "../../../shared/hooks/useSorting";
import useDrawer from "../../../shared/hooks/useDrawer";
import TabExtraContent from "../../../shared/components/TabExtraContent";
import FilterButtons from "../../../shared/components/FilterButtons";
import { Filters } from "../../../models/filters.model";
import { TableConfig } from "../../../enums/tableConfig.type";
import './toReturnDocuments.scss'
import ColumnOptions from "../../../shared/components/ColumnOptions";
import AcknowledgeDrawer from "../AcknowledgeDetails";
import { columns } from "./columns";
import { modLoansTypes } from "../../../enums/modLoans.type";
import { LoanService } from "../../../services/Loan/loan.service";
import MODFilters from "../MODFilters";
import { modDocumentsConstants } from "../DownloadReportModal/constants";
import DownloadReportModal from "../DownloadReportModal";
import { ColumnOrder } from "../../../enums/columnOrder.enum";
import { getColumnKeys, getColumnTitles, getDefaultColumns, getDeletedColumns, hasColumnDifference } from "../../../shared/utils/columnUtil";
import { ColumnsService } from "../../../services/Columns/columns.service";
import { LocalStorageHelper } from "../../../shared/utils/localStorageHelper";
import { LocalStorage } from "../../../enums/localStorage.enum";

const sortBy = {
  code: "code",
  security: "security",
  customerName: "customer_name",
  croName: "created_by.firstname",
  branch: "branch",
  loanAmount: "loanAmount",
  approvedDate: "approvedDate",
  securedType: "secured_type",
  requestedLoanAmt: "requested_loan_amt",
};

const ToReturnDocuments = () => {
  const [defaultColumns, setDefaultColumns] =
    useState<TableColumnsType<Loan>>(columns);
  const [documents, setDocuments] = useState<Loan[]>([]);
  const [filters, setFilters] = useState<Filters>(new Filters());
  const { columnConfig, setColumnConfig, getColumnOrder } = useTableConfig();
  const [filteredColumns, setFilteredColumns] = useState<string[]>(
    columnConfig?.modDocuments ?? []
  );
  const [documentsTotalPage, setDocumentsTotalPage] = useState(1);
  const [displayDocument, setDisplayDocument] = useState<Loan>();
  const { loading, getLoans } = LoanService()

  const {
    params: toReturnParams,
    updateParams,
    getParams,
    handleSearch: handleToReturnSearch,
  } = useQueryParams({
    params: new LoanParams(),
  });

  const {
    visible: documentModalVisible,
    toggleVisibility: toggleDocumentModalVisibility,
  } = useDrawer({});

  const { updateSortData } = useSorting<Loan>({ sortBy });

  const handleChange: TableProps<Loan>["onChange"] = (
    pagination,
    _,
    sorter
  ) => {
    const sortData = updateSortData(sorter as SorterResult<Loan>);
    updateParams({
      ...toReturnParams,
      ...sortData,
      page: pagination?.current,
    });
  };

  const handleUpdateParams = () => updateParams(getParams());

  useEffect(() => {
    setColumnConfig(TableConfig.MOD_OFFICER, filteredColumns);
  }, [filteredColumns]);

  const fetchDocuments = async () => {
    const updatedParams = {
      ...toReturnParams,
      searchText: toReturnParams.searchText,
      modStatus: modLoansTypes.TO_RETURNED
    };
    const loanData = await getLoans(updatedParams);
    if (loanData?.loans) setDocuments(loanData?.loans);
    if (loanData?.meta) {
      setDocumentsTotalPage(loanData?.meta?.totalCount ?? 1);
      loanData?.meta?.filters && setFilters(loanData?.meta?.filters);
    }
  };
  const { visible: openDownloadModal, toggleVisibility: toggleDownloadModal } = useDrawer({})
  useEffect(() => {
    fetchDocuments()
  }, [toReturnParams])

  const {
    visible: toReturnFilterVisible,
    toggleVisibility: toggleToReturnFilterVisibility,
  } = useDrawer({});

  const {
    visible: toReturnSettingsVisible,
    toggleVisibility: toggleToReturnSettingsVisibility,
  } = useDrawer({});

  const handleRowChange = (record: Loan) => ({
    onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
      toggleDocumentModalVisibility();
      setDisplayDocument(record);
    },
  });

  const handleDownloadModal = () => {
    toggleDownloadModal(!openDownloadModal);
  };

  const setColumns = () => {
    if (originalOrder && originalOrder.length > 0) {
      const updatedColumns = getDefaultColumns(columns, storedColumnOrder, storedColumnKeys, originalOrder);
      setDefaultColumns(updatedColumns);
    } else {
      setDefaultColumns(columns);
    }
  }
  useEffect(() => {
    fetchColumnOrder()
  }, []);

  const fetchColumnOrder = async () => {
    const columnOrder = await getColumns({
      pageName: ColumnOrder.TO_RETURN_LOANS,
      employeeId: employee.id
    })
    if (!columnOrder) {
      publishColumns()
    }
    setOriginalOrder(columnOrder?.columnArray ?? [])
    setColumnConfig(ColumnOrder.TO_RETURN_LOANS, columnOrder?.columnArray ?? [])
  }

  const setDeletedColumns = () => {
    setFilteredColumns(getColumnTitles(getDeletedColumns(columns, originalOrder)))
  }
  const tableColumnTitles = getColumnTitles(columns)
  const storedColumnOrder = getColumnOrder(ColumnOrder.TO_RETURN_LOANS);
  const storedColumnKeys = getColumnKeys(storedColumnOrder)
  const employee = LocalStorageHelper.getItem(LocalStorage.EMPLOYEE);
  const [originalOrder, setOriginalOrder] = useState<string[]>([])
  const { createColumns, getColumns, updateColumns } = ColumnsService()

  const publishColumns = async () => {
    await createColumns({
      pageName: ColumnOrder.TO_RETURN_LOANS,
      columnArray: tableColumnTitles,
      employeeId: employee?.id
    })
  }

  useEffect(() => {
    if (hasColumnDifference(columns, defaultColumns)) {
      publishColumns();
    }
  }, []);

  useEffect(() => {
    if (originalOrder && originalOrder.length) {
      setColumns();
      setDeletedColumns()
    }
  }, [originalOrder]);

  const updateColumn = async (columns: ColumnsType<Loan>) => {
    await updateColumns({
      columnArray: getColumnTitles(columns),
      pageName: ColumnOrder.TO_RETURN_LOANS
    })
  }

  return (
    <div className="to-return-documents">
      {!loading && (
        <FilterButtons filters={filters} onFilter={handleUpdateParams} />
      )}
      <div className="to-return-extra-content">
        <TabExtraContent
          searchProps={{
            onSearch: handleToReturnSearch,
            placeholder: "Search Documents",
            value: toReturnParams.searchText,
          }}
          filterClick={toggleToReturnFilterVisibility}
          settingsClick={toggleToReturnSettingsVisibility}
        />
        <Col offset={1}>
          <Button onClick={handleDownloadModal} className="download-button">{modDocumentsConstants.DOWNLOAD_BUTTON_TEXT}</Button>
        </Col>
      </div>
      <Table
        className={`to-return-documents-table`}
        rowKey="id"
        scroll={{ x: true }}
        columns={defaultColumns.filter(
          (col) => !filteredColumns.includes(col.title as string)
        )}
        dataSource={documents}
        loading={loading}
        onRow={handleRowChange}
        showSorterTooltip={false}
        onChange={handleChange}
        pagination={{
          current: toReturnParams?.page,
          total: documentsTotalPage,
          hideOnSinglePage: true,
        }}
      />
      <Drawer
        placement="right"
        onClose={() => toggleToReturnFilterVisibility(false)}
        visible={toReturnFilterVisible}
        width="916"
        title="Filters"
        closable
        destroyOnClose
      >
        <MODFilters
          onClose={() => toggleToReturnFilterVisibility(false)}
          onFilter={updateParams}
        />
      </Drawer>
      <Drawer
        placement="right"
        onClose={() => toggleToReturnSettingsVisibility(false)}
        visible={toReturnSettingsVisible}
        width={500}
        title="Column Options"
        destroyOnClose
      >
        <ColumnOptions
          defaultColumns={defaultColumns}
          filteredColumns={filteredColumns}
          setFilteredColumns={setFilteredColumns}
          onClose={() => toggleToReturnSettingsVisibility(false)}
          setDefaultColumns={setDefaultColumns}
          tableColumns={columns}
          onAction={updateColumn}
          tableKey={ColumnOrder.TO_RETURN_LOANS}
        />
      </Drawer>
      <Drawer
        placement="right"
        onClose={() => toggleDocumentModalVisibility(false)}
        visible={documentModalVisible}
        width={600}
        destroyOnClose
        title="Document Return"
      >
        <AcknowledgeDrawer fetchDocuments={fetchDocuments} document={displayDocument} onClose={() => toggleDocumentModalVisibility(false)} />
      </Drawer>
      <DownloadReportModal reportType={modDocumentsConstants.TO_RETURN} visible={openDownloadModal} toggleModal={toggleDownloadModal} />
    </div>
  );
};

export default ToReturnDocuments;
