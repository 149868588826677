import { ColumnProps } from "antd/lib/table";
import React from "react";
import { Repayments } from "../../../models/repayments.model";
import { removeUnderscore } from "../../../shared/utils/removeUnderscore";
import { dateTimeFormatter } from "../../../shared/utils/formatter";
import { DateFormat } from "../../../enums/dateFormat.type";
import { Col, Popover, Row } from "antd";
import { TransactionActivity } from "../../../enums/transactionActivity";
import { fillEmptyData } from "../../../shared/utils/fillEmptyData";

export const columns: ColumnProps<Repayments>[] = [
  {
    title: "Loan ID",
    dataIndex: "code",
    sorter: true,
  },
  {
    title: "Partner ID",
    dataIndex: "laf",
    render: (laf: string) => fillEmptyData(laf?.toUpperCase()),
  },
  {
    title: "Customer Name",
    dataIndex: "customerName",
    sorter: true,
  },
  {
    title: "Branch",
    dataIndex: "branch",
  },
  {
    title: "Loan Type",
    dataIndex: "loanType",
    sorter: true,
  },
  {
    title: "Repayment Date",
    dataIndex: "repaymentDate",
    render: (date) => dateTimeFormatter(date, DateFormat.DATE),
    sorter: true,
  },
  {
    title: "Principal",
    dataIndex: "principal",
  },
  {
    title: "Interest",
    dataIndex: "interest",
    render: (interest) => Math.round(Math.abs(interest)),
  },
  {
    title: "Total Amount",
    dataIndex: "totalAmount",
    render: (totalAmount) => Math.round(totalAmount),
  },
  {
    title: "Pending Amount",
    dataIndex: "pendingAmount",
    render: (pendingAmount) => Math.round(pendingAmount),
  },

  {
    title: "History ",
    dataIndex: "history",
    render: (_, repayment) => {
      const validTransactions = (repayment?.paymentTransactions || []).filter(
        (data) => data?.activity !== TransactionActivity.WALLET
      );

      return validTransactions.length > 0 ? (
        <Popover
          placement="left"
          content={
            <>
              <Row
                className="mb-2 text-bold repayment-details__history"
                gutter={[20, 10]}
                justify="space-between"
              >
                <Col span={8}>Receipt Number</Col>
                <Col span={4}>Amount</Col>
                <Col span={4}>Date</Col>
                <Col span={4}>Time</Col>
              </Row>
              {validTransactions.map(
                (data, index) =>
                  data && (
                    <Row gutter={[20, 10]} justify="space-between" key={index}>
                      <Col span={8}>{data.receiptNo}</Col>
                      <Col span={4}>{data.amount}</Col>
                      <Col span={4}>
                        {dateTimeFormatter(
                          data.createdAt || "",
                          DateFormat.DATE
                        )}
                      </Col>
                      <Col span={4}>
                        {dateTimeFormatter(
                          data.createdAt || "",
                          DateFormat.TIME
                        )}
                      </Col>
                    </Row>
                  )
              )}
            </>
          }
          title=""
          trigger="click"
        >
          <p className="repayment-details__history-text text-link">View Details &gt; </p>
        </Popover>
      ) : (
        "-"
      );
    },
  },

  {
    title: "Wallet Balance ",
    dataIndex: "walletBalance",
  },
  {
    title: "Paid Date",
    dataIndex: "paidDate",
    render: (paidDate) => dateTimeFormatter(paidDate, DateFormat.DATE),
    sorter: true
  },

  {
    title: "Repayment remarks",
    dataIndex: "repaymentRemarks",
    render: (status, record) => {
      const className =
        "active-status--" + record.status?.split("_")?.join("-");
      return (
        <span className={` text-capitalize  ${className}`}>
          {removeUnderscore(record?.repaymentRemarks ?? "")}
        </span>
      );
    },
  },
  {
    title: "Status",
    dataIndex: "status",
    sorter: true,
    render: (status, record) => {
      const className =
        "active-status--" + record.status?.split("_")?.join("-");
      return (
        <span className={` text-capitalize  ${className}`}>
          {removeUnderscore(record?.status ?? "")}
        </span>
      );
    },
  },
  {
    title: "Payment Mode",
    dataIndex: "paymentMode",
    render: (mode: string) => (
      <span className="text-capitalize">{fillEmptyData(mode)}</span>
    ),
  },
];
