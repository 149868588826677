import { ColumnGroupType, ColumnProps, ColumnType } from "antd/lib/table";
import { Key } from "react";

export const sortColumnsByOrder = <T>(
  columns: (ColumnType<T> | ColumnGroupType<T>)[], 
  order: (string | number)[], 
  keyExtractor: (col: ColumnType<T> | ColumnGroupType<T>) => string | number | undefined
): (ColumnType<T> | ColumnGroupType<T>)[] => {
  return columns
    .filter((col) => {
      const key = keyExtractor(col);
      return key !== undefined && order.includes(key);
    })
    .sort((a, b) => {
      const indexA = order.indexOf(keyExtractor(a) as string | number);
      const indexB = order.indexOf(keyExtractor(b) as string | number);
      return indexA - indexB;
    });
};

export const getDefaultColumns = <T>(
  columns: (ColumnType<T> | ColumnGroupType<T>)[], 
  storedColumnOrder: string[], 
  storedColumnKeys: (string | number)[], 
  initialColumnOrder: string[]
): (ColumnType<T> | ColumnGroupType<T>)[] => {
  if (storedColumnOrder?.length && storedColumnKeys?.length) {
    return sortColumnsByOrder(
      columns,
      storedColumnKeys,
      (col) => col.key
    );
  }

  if (initialColumnOrder.length) {
    return sortColumnsByOrder(
      columns,
      initialColumnOrder,
      (col) => String(col.title).trim()
    );
  }


  return columns;
};
  
  interface ExtendedColumnProps<T> extends ColumnProps<T> {
    key: Key
  }
  export const getColumnKeys = <T>(
    columns: ExtendedColumnProps<T>[]
  ): number[] => {
    return columns
      ?.map((col) => col.key)
      .filter((key): key is number => !isNaN(Number(key))) as number[]
  };
  
  export const getColumnTitles = <T>(columns: (ColumnType<T> | ColumnGroupType<T>)[] | undefined): string[] => {
    return columns
      ?.map((col) => String(col.title))
      .filter((title) => title) ?? [];
  };
  
  export const filterColumns = <T>(
    columns: (ColumnType<T> | ColumnGroupType<T>)[],
    deletedColumns: string[]
  ): ExtendedColumnProps<T>[] => {
    return columns
      .filter((column) => {
        const title = String(column.title).trim();
        if (!title) {
          return false;
        }
        if (deletedColumns.includes(title)) {
          return false;
        }
        return true;
      })
      .filter((column): column is ExtendedColumnProps<T> => {
        return typeof column.title === 'string' && column.title.trim().length > 0;
      });
  };
  
  
  
  export const getDeletedColumns = <T>(
    columns: (ColumnType<T> | ColumnGroupType<T>)[],
    originalOrder: string[]
  ) => {
    return columns.filter((col) => {
      const title = col.title ? String(col.title).trim() : '';
      return title && !originalOrder?.includes(title);
    });
  };
  
  export const hasColumnDifference = <T>(
    columns: (ColumnGroupType<T> | ColumnType<T>)[], 
    defaultColumns: (ColumnGroupType<T> | ColumnType<T>)[]): boolean => {

    const columnTitles = columns.map(col => String(col.title)?.trim()).sort();
    const defaultColumnTitles = defaultColumns.map(col => String(col.title)?.trim()).sort();
    return columnTitles.join() !== defaultColumnTitles.join();
  };
  
  
  