/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Col, Row, Select, Spin } from "antd";
import DashStats from "../DashStats";
import StatsGraph from "../../../shared/components/StatsGraph";
import { AuthContext } from "../../../context/AuthContext";
import { DashboardService } from "../../../services/Dashboard/dashboardService.service";
import "../dashboard.scss";
import CustomRangePicker from "../../../shared/components/CustomRangePicker";
import { Graph } from "../../../enums/Graph.type";
import { getNCharacters } from "../../../shared/utils/getNCharacters";
import { DateRange } from "../../../shared/types/date.type";
import useBranch from "../../../shared/hooks/useBranch";
import { Meta } from "../../../models/meta.modal";
import { Moment } from "moment"
import { MetaService } from "../../../services/Meta/meta.service";

import { CardProps } from "../../../shared/types/card.type";
import { User } from "../../../enums/user.type";
import {
  MISDashboard as MISDashboardModel,
  MISDashboardParams,
} from "../../../models/misDashboards.model";
import { GraphType } from "../../../enums/graphType";
import { restrictMonths } from "../../../shared/utils/dateUtils";
import { HeadOfficerDashboardConstants } from "../HeadOfficerDashboard/constants";

interface MISDashboardProps {
  selectedRole?: string;
  onRoleChange: (role: User) => void;
}

interface GraphFilters {
  branch?: string;
  loanType?: string;
  loanApplication?: DateRange;
  loanApproval?: DateRange;
}

const MISDashboard = (props: MISDashboardProps) => {
  const { selectedRole, onRoleChange } = props;
  const { user } = AuthContext();
  //permission yet not granted
  const { branches, loading: branchLoading, handleBranchScroll } = useBranch({ allBranch: false });
  const { getDashboard, loading, getHeadofficerGraph } = DashboardService();
  const { getLoanTypes } = MetaService();
  const [loanTypesOptions, setLoanTypeOptions] = useState<Meta[]>([]);
  const [dashboardGraph, setDashboardGraph] = useState<MISDashboardModel>();
  const [dashboardData, setDashboardData] = useState<MISDashboardModel>();
  const [filter, setFilter] = useState<GraphFilters>();

  useEffect(() => {
    handleGetDashboard({ user: selectedRole });
    fetchLoanTypes();

    //this line will be repaced by MIS graph once implemented
    handleDashboardGraph(Graph.APPLICATION);
    handleDashboardGraph(Graph.APPROVAL);
  }, []);
  const fetchLoanTypes = async () => {
    const data = await getLoanTypes();
    data && setLoanTypeOptions(data);
  };

  const handleGetDashboard = async (params?: MISDashboardParams) => {
    const data = await getDashboard(params);
    data && setDashboardData(data);
  };

  const handleFilter = (value: MISDashboardParams) =>
    onRoleChange(value.user as User);

  const handleDateChange = (value: DateRange) => handleGetDashboard(value);

  const handleGraphData = (
    graphData: GraphType.LOAN_APPLICATION | GraphType.LOAN_APPROVALS
  ): { key: string; count: number; value: number }[] => {
    const months: string[] = (dashboardGraph?.[graphData]?.map((graph) => String(graph.month)) || [])
    return dashboardGraph
      ? months.map((key) => ({
        key,
        count:
          (dashboardGraph[graphData] || []).find(
            (item) => getNCharacters(String(item?.month), 3) === key
          )?.sum ?? 0,
        value:
          (dashboardGraph[graphData] || []).find(
            (item) => getNCharacters(String(item?.month), 3) === key
          )?.count ?? 0,
      }))
      : [];
  };

  const restrictMonthsInGraph = (
    current: Moment,
    selectedFromDate: Moment | null,
    maxMonths: number = 11
  ): boolean => {
    return !!selectedFromDate && restrictMonths(current, selectedFromDate, maxMonths);
  };

  const card: CardProps[] = [
    {
      label: "Total Outstanding",
      count: dashboardData?.totalOutstanding,
    },
    {
      label: "Total Disbursement",
      count: dashboardData?.totalDisbursement,
    },
    {
      label: "Total own Portifolio",
      count: dashboardData?.totalOwnPortfolio,
    },
    {
      label: "Total managed Portifolio",
      count: dashboardData?.totalManagedPortfolio,
    },

    {
      label: "Total Loans",
      count: dashboardData?.totalLoans,
    },
    {
      label: "Total Borrowers",
      count: dashboardData?.totalBorrowers,
    },
    {
      label: "Total Active Loans",
      count: dashboardData?.totalActiveLoans,
    },
    {
      label: "Total Active Borrowers",
      count: dashboardData?.totalActiveBorrowers,
    },
  ];
  const handleDashboardGraph = async (
    type: Graph,
    values?: DateRange,
    filter?: MISDashboardParams
  ) => {
    //this headOfficer Graph will be repaced by MIS Dashboard
    const data = await getHeadofficerGraph({ ...values, ...filter }, type);

    data &&
      setDashboardGraph((existingData) => ({
        ...existingData,
        [type === Graph.APPLICATION
          ? GraphType.LOAN_APPLICATION
          : GraphType.LOAN_APPROVALS]: data,
      }));
  };

  const onChangeGraph = (values: DateRange, graphType: Graph) => {
    const graphFilters = graphType === Graph.APPLICATION ? { loanType: filter?.loanType, loanApplication: values } : { branch: filter?.branch, loanApproval: values }
    setFilter({...filter, ...graphFilters})
    handleDashboardGraph(graphType, values, {
      ...filter,
      ...graphFilters
    })
  }

  const onChangeHandler = (value: string, type: Graph) => {
    if (type === Graph.APPLICATION) {
      handleDashboardGraph(
        Graph.APPLICATION,
        filter?.loanApplication,
        { loanType: value }
      );
      setFilter({ ...filter, loanType: value });
    } else if (type === Graph.APPROVAL) {
      handleDashboardGraph(
        Graph.APPROVAL,
        filter?.loanApproval,
        { branch: value }
      );
    }
  };

  return (
    <div className="mis-manager dashboard">
      {loading && (
        <div className="spin-overlay">
          <Spin />
        </div>
      )}
      <Row gutter={[30, 16]}>
        <Col span={10}>
          <DashStats
            employee={user?.employee}
            card={card}
            onFilter={handleFilter}
            role={selectedRole}
            onDateChange={handleDateChange}
          />
        </Col>
        <Col span={14}>
          {dashboardGraph?.loanApplications && (
            <StatsGraph
              title={HeadOfficerDashboardConstants.LOAN_APPLICATIONS}
              hasCard
              data={handleGraphData(GraphType.LOAN_APPLICATION)}
              axes={{
                x: HeadOfficerDashboardConstants.TOTAL_LOANS,
                y: HeadOfficerDashboardConstants.TOTAL_AMOUNT,
              }}
              barColor={HeadOfficerDashboardConstants.APPLICATIONS_BAR_COLOR}
              extraContent={
                <Row gutter={[20, 20]}>
                  <Col span={14}>
                    <CustomRangePicker
                      disabledDateCondition={restrictMonthsInGraph}
                      onChange={(values) => onChangeGraph(values, Graph.APPLICATION)}
                    />
                  </Col>
                  <Col span={10}>
                    <Select
                      options={loanTypesOptions}
                      placeholder={HeadOfficerDashboardConstants.LOAN_PLACEHOLDER}
                      onChange={(value) => onChangeHandler(value, Graph.APPLICATION)}
                      allowClear
                    />
                  </Col>
                </Row>
              }
            />
          )}

          {dashboardGraph?.loanApprovals && (
            <StatsGraph
              title={HeadOfficerDashboardConstants.LOAN_APPROVALS}
              hasCard
              data={handleGraphData(GraphType.LOAN_APPROVALS)}
              axes={{
                x: HeadOfficerDashboardConstants.APPROVED_LOANS,
                y: HeadOfficerDashboardConstants.TOTAL_AMOUNT,
              }}
              barColor={HeadOfficerDashboardConstants.APPROVAL_BAR_COLOR}
              extraContent={
                <Row gutter={[20, 20]}>
                  <Col span={14}>
                    <CustomRangePicker
                      onChange={(values) => onChangeGraph(values, Graph.APPROVAL)}
                      disabledDateCondition={restrictMonthsInGraph}
                    />
                  </Col>
                  <Col span={10}>
                    <Select
                      className="branch-select"
                      allowClear
                      options={branches}
                      loading={branchLoading}
                      placeholder={HeadOfficerDashboardConstants.BRANCH_PLACEHOLDER}
                      onChange={(value) => onChangeHandler(value, Graph.APPROVAL)}
                      onPopupScroll={handleBranchScroll}
                    />
                  </Col>
                </Row>
              }
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default MISDashboard;
