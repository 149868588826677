import { ReportNameType } from "../../enums/reportName.type";
import { ReportConstants } from "../../views/Reports/constants";

const reportOptions = [
  {
    label: "Demand",
    value: ReportNameType.DEMAND,
  },
  {
    label: "Repayment",
    value: ReportNameType.REPAYMENT,
  },
  {
    label: "Overdue",
    value: ReportNameType.OVERDUE,
  },
  {
    label: "Disbursement",
    value: ReportNameType.DISBURSEMENT,
  },
  {
    label: "Outstanding",
    value: ReportNameType.OUTSTANDING,
  },

  {
    label: "Payment",
    value: ReportNameType.PAYMENT,
  },
  {
    label: "Insurance",
    value: ReportNameType.INSURANCE,
  },
  {
    label: "Book Debt",
    value: ReportNameType.BOOK_DEBT
  },
  {
    label: ReportConstants.PRECLOSE_REPORT,
    value: ReportNameType.PRECLOSE
  }
];

export default reportOptions;
