/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "../loanClaims.scss";
import useQueryParams from "../../../shared/hooks/useQueryParams";
import { LoanClaimsModel, LoanClaimsParams } from "../../../models/loanClaims.model";
import Table from "../../../shared/components/Table";
import useTableConfig from "../../../shared/hooks/useTableConfig";
import useSorting from "../../../shared/hooks/useSorting";
import useDrawer from "../../../shared/hooks/useDrawer";
import { Drawer, TableColumnsType, TableProps } from "antd";
import { ColumnsType, SorterResult } from "antd/lib/table/interface";
import { generatePath, useNavigate } from "react-router-dom";
import * as AppRoutes from "../../../routes/routeConstants/appRoutes";
import TabExtraContent from "../../../shared/components/TabExtraContent";
import ColumnOptions from "../../../shared/components/ColumnOptions";
import { TableConfig } from "../../../enums/tableConfig.type";
import { ColumnProps } from "antd/lib/table";
import { fillEmptyData } from "../../../shared/utils/fillEmptyData";
import { DateFormat } from "../../../enums/dateFormat.type";
import { currencyFormatter, dateTimeFormatter } from "../../../shared/utils/formatter";
import { ClaimsService } from "../../../services/Claims/claims.service";
import { LoansClaimsStatus } from "../../../enums/loanClaimsStatuses.type";
import { toTitleCase } from "../../../shared/utils/titleCaseConverter";
import { removeUnderscore } from "../../../shared/utils/removeUnderscore";
import { ColumnOrder } from "../../../enums/columnOrder.enum";
import { getColumnKeys, getColumnTitles, getDefaultColumns, getDeletedColumns, hasColumnDifference } from "../../../shared/utils/columnUtil";
import { LocalStorageHelper } from "../../../shared/utils/localStorageHelper";
import { LocalStorage } from "../../../enums/localStorage.enum";
import { ColumnsService } from "../../../services/Columns/columns.service";

const sortBy = {
  loanCode: "loan_code",
  role: "role",
  nameOfExpired: "name_of_expired",
  deathDate: "death_date"
};

const ProcessingClaims = () => {
  const {
    params: processingParams,
    handleSearch,
    updateParams,
  } = useQueryParams<LoanClaimsParams>({
    params: new LoanClaimsParams(),
  });

  const columns: ColumnProps<LoanClaimsModel>[] = [
    {
        title: "Loan ID",
        dataIndex: "loanCode",
        render: (code: string) => fillEmptyData(code?.toUpperCase()),
        sorter: true,
    },
    {
        title: "Name of Expired",
        dataIndex: "nameOfExpired",
        render: (name: string) => fillEmptyData(name),
    },
    {
      title: "Customer Name",
      dataIndex: "customerName",
      render: (name: string) => fillEmptyData(name),
    },
    {
      title: "Role",
      dataIndex: "role",
      render: (role: string) => fillEmptyData(toTitleCase(removeUnderscore(role))),
      sorter: true,
    },
    {
        title: "Death Date",
        dataIndex: "deathDate",
        render: (date: string) =>
            dateTimeFormatter(date, DateFormat.DATE),
        sorter: true
    },
    {
      title: "Claim Sent On",
      dataIndex: "claimSentOn",
      render: (date: string) => dateTimeFormatter(date, DateFormat.DATE),
    },
    {
      title: "Branch",
      dataIndex: "branch.name",
      render: (text, record) => {
        return fillEmptyData(record?.branch);
      },
    },
    {
      title: "CRO",
      dataIndex: "cro",
      render: (text: string, record: LoanClaimsModel) => {
        return fillEmptyData(toTitleCase(record?.croName ?? ""))
      },
    },
    {
        title: "Total Insured",
        dataIndex: "totalInsured",
        render: (_, record: LoanClaimsModel) => {
            return currencyFormatter({amount: record.totalInsured, hasCurrencySign: true})
        },
    },
    {
        title: "Outstanding",
        dataIndex: "outstanding",
        render: (_, record: LoanClaimsModel) => {
            return currencyFormatter({amount: record.outstanding, hasCurrencySign: true})
        },
    },
    {
      title: "To Customer",
      dataIndex: "toCustomer",
      render: (_, record: LoanClaimsModel) => {
        return currencyFormatter({amount: record?.toCustomer, hasCurrencySign: true})
      },
    },
    {
      title: "To Velicham",
      dataIndex: "toVelicham",
      render:(_, record: LoanClaimsModel) => {
        return currencyFormatter({amount: record?.toVelicham, hasCurrencySign: true})
      }
    },
    {
        title: "Insurer",
        dataIndex: "insurer",
        render: (_, record: LoanClaimsModel) => (fillEmptyData(toTitleCase(removeUnderscore(record.insurer))))
    },
    {
        title: "Nominee Name",
        dataIndex: "nomineeName",
        render: (_, record: LoanClaimsModel) => fillEmptyData(toTitleCase(removeUnderscore(record.nomineeName))),
        sorter: true
    }
]

  const { getClaims, loading } = ClaimsService()
  const [totalClaims, setTotalClaims] = useState(1);
  const { columnConfig, setColumnConfig, getColumnOrder } = useTableConfig();
  const [filteredColumns, setFilteredColumns] = useState<string[]>(
    columnConfig?.loanClaims ?? []
  );
  const [defaultColumns, setDefaultColumns] =
    useState<TableColumnsType<LoanClaimsModel>>(columns);
  const [claims, setClaims] = useState<LoanClaimsModel[]>([]);
  const { updateSortData } = useSorting<LoanClaimsModel>({ sortBy });
  useEffect(() => {
    setColumnConfig(TableConfig.LOAN_CLAIMS, filteredColumns);
  }, [filteredColumns]);

  const {
    visible: processingSettingsVisible,
    toggleVisibility: toggleProcessingSettingsVisibility,
  } = useDrawer({});

  const handleChange: TableProps<LoanClaimsModel>["onChange"] = (
    pagination,
    _,
    sorter
  ) => {
    const sortData = updateSortData(sorter as SorterResult<LoanClaimsModel>);
    updateParams({
      ...processingParams,
      ...sortData,
      page: pagination?.current,
    });
  };

  const navigate = useNavigate();

  useEffect(() => {
    fetchClaims();
  }, [processingParams, processingParams.searchText]);

  const fetchClaims = async () => {
    const updatedParams = {
      ...processingParams,
      searchText: processingParams.searchText,
      status: LoansClaimsStatus.PROCESSING
    };
    const claimsData = await getClaims(updatedParams);
    if (claimsData?.claims) setClaims(claimsData?.claims);
    if (claimsData?.meta) {
      setTotalClaims(claimsData?.meta?.totalCount ?? 1);
    }
  };

  const setColumns = () => {
    if (originalOrder && originalOrder.length > 0) {
      const updatedColumns = getDefaultColumns(columns, storedColumnOrder, storedColumnKeys, originalOrder);
      setDefaultColumns(updatedColumns);
    } else {
      setDefaultColumns(columns);
    }
  }
  useEffect(() => {
    fetchColumnOrder()
  }, []);

  const fetchColumnOrder = async () => {
    const columnOrder = await getColumns({
      pageName: ColumnOrder.PROCESSING_CLAIMS,
      employeeId: employee.id
    })
    if (!columnOrder) {
      publishColumns()
    }
    setOriginalOrder(columnOrder?.columnArray ?? [])
    setColumnConfig(ColumnOrder.PROCESSING_CLAIMS, columnOrder?.columnArray ?? [])
  }

  const setDeletedColumns = () => {
    setFilteredColumns(getColumnTitles(getDeletedColumns(columns, originalOrder)))
  }
  const tableColumnTitles = getColumnTitles(columns)
  const storedColumnOrder = getColumnOrder(ColumnOrder.PROCESSING_CLAIMS);
  const storedColumnKeys = getColumnKeys(storedColumnOrder)
  const employee = LocalStorageHelper.getItem(LocalStorage.EMPLOYEE);
  const [originalOrder, setOriginalOrder] = useState<string[]>([])
  const { createColumns, getColumns, updateColumns } = ColumnsService()

  const publishColumns = async () => {
    await createColumns({
      pageName: ColumnOrder.PROCESSING_CLAIMS,
      columnArray: tableColumnTitles,
      employeeId: employee?.id
    })
  }

  useEffect(() => {
    if (hasColumnDifference(columns, defaultColumns)) {
      publishColumns();
    }
  }, []);

  useEffect(() => {
    if (originalOrder && originalOrder.length) {
      setColumns();
      setDeletedColumns()
    }
  }, [originalOrder]);

  const updateColumn = async (columns: ColumnsType<LoanClaimsModel>) => {
    await updateColumns({
      columnArray: getColumnTitles(columns),
      pageName: ColumnOrder.PROCESSING_CLAIMS
    })
  }

  return (
    <div className="processing-claims">
      <div className="processing-claims-extra-content">
        <TabExtraContent
          searchProps={{
            onSearch: handleSearch,
            placeholder: "Search Document",
            value: processingParams.searchText,
          }}
          settingsClick={toggleProcessingSettingsVisibility}
        />
      </div>
      <Table
        onChange={handleChange}
        scroll={{ x: true }}
        className="processing-claims-table"
        columns={defaultColumns.filter(
          (col) => !filteredColumns.includes(col.title as string)
        )}
        loading={loading}
        dataSource={claims}
        rowKey={(record: LoanClaimsModel) => record?.loanId as number}
        onRow={(record: LoanClaimsModel) => ({
          onClick: () => {
            navigate(
              generatePath(AppRoutes.INDIVIDUAL_LOAN_CLAIM, {
                loanId: String(record?.loanId),
              })
            );
          },
        })}
        pagination={{
          current: processingParams?.page,
          hideOnSinglePage: true,
          total: totalClaims,
        }}
      />
      
      <Drawer
        placement="right"
        visible={processingSettingsVisible}
        destroyOnClose
        onClose={() => toggleProcessingSettingsVisibility(false)}
        title="Column Options"
        width={500}
      >
        <ColumnOptions
          defaultColumns={defaultColumns}
          filteredColumns={filteredColumns}
          setFilteredColumns={setFilteredColumns}
          onClose={() => toggleProcessingSettingsVisibility(false)}
          setDefaultColumns={setDefaultColumns}
          tableColumns={columns}
          onAction={updateColumn}
          tableKey={ColumnOrder.PROCESSING_CLAIMS}
        />
      </Drawer>
    </div>
  );
};

export default ProcessingClaims;
