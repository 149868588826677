import React from 'react'
import "./payu.scss"
import { PayuConstants } from './constants'
import useQueryParams from '../../shared/hooks/useQueryParams'

const PayuView = () => {
    const { getParams } = useQueryParams({
        params: {}
    })
    return (
        <div className='payu'>
            <iframe src={PayuConstants.LINK.concat(getParams().id)} title={PayuConstants.TITLE} />
        </div>
    )
}

export default PayuView