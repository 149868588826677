import * as Yup from "yup";
import { UpdateLoanAmountConstants } from "./constants";

export const UpdateLoanAmountValidation = (loanAmount: number = 0) => {
    return Yup.object().shape({
        amount: Yup.number()
            .required(UpdateLoanAmountConstants.VALIDATION)
            .min(1, UpdateLoanAmountConstants.GREATER_VALIDATION)
            .max(loanAmount, UpdateLoanAmountConstants.LESSER_VALIDATION)
    })
}