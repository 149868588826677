import { ColumnProps } from "antd/lib/table";
import { Loan } from "../../models/loan.model";
import { fillEmptyData } from "../../shared/utils/fillEmptyData";
import { dateTimeFormatter } from "../../shared/utils/formatter";
import { removeUnderscore } from "../../shared/utils/removeUnderscore";
import React from "react";
import { DateFormat } from "../../enums/dateFormat.type";
import { getFullName } from "../../shared/utils/getFullName";

export const columns: ColumnProps<Loan>[] = [
  {
    key: 1,
    title: "Loan ID",
    dataIndex: "code",
    render: (code: string) => code?.toUpperCase(),
    sorter: true,
  },
  {
    key: 2,
    title: "Partner ID",
    dataIndex: "laf",
    render: (laf: string) => fillEmptyData(laf?.toUpperCase()),
  },
  {
    key: 3,
    title: "Channel ",
    dataIndex: "organizationName",
    sorter: true,
  },
  {
    key: 4,
    title: "BC Tag Status",
    dataIndex: "currentStatusTag",
    render: (_, record) => {
      const className =
        "active-status--" + record.currentStatusTag?.split("_")?.join("-");
      return (
        <span
          className={`text-success text-capitalize active-status ${className}`}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          {removeUnderscore(record.getBCTagLabel() ?? "")}
        </span>
      );
    },
  },
  {
    key: 5,
    title: "Customer Name",
    dataIndex: "name",
    render: (_: string, record: Loan) => record?.customer?.getName(),
    sorter: true,
  },
  {
    key: 6,
    title: "Loan Type",
    dataIndex: "loanTypeName",
    sorter: true,
  },
  {
    key: 7,
    title: "Branch",
    render: (code: string, loan: Loan) => loan?.branch?.name,
    dataIndex: "branch",
  },
  {
    key: 8,
    title: "Center",
    dataIndex: "centerName",
    sorter: true,
  },
  {
    key: 9,
    title: "CRO",
    dataIndex: "createdByFirstName",
    sorter: true,
    render: (_: string, record: Loan) => getFullName(record?.createdBy),
  },
  {
    key: 10,
    title: "Total Amount",
    dataIndex: "requestedLoanAmt",
    render: (amount: number) => amount,
  },
  {
    key: 11,
    title: "Applied Date",
    dataIndex: "appliedDate",
    render: (appliedDate: string) =>
      dateTimeFormatter(appliedDate, DateFormat.DATE),
  },
  {
    key: 12,
    title: "Submitted Date",
    dataIndex: "submittedAt",
    render: (submittedAt: string) =>
      dateTimeFormatter(submittedAt, DateFormat.DATE),
  },
  {
    key: 13,
    title: "Approved Date",
    dataIndex: "approvedDate",
    render: (approvedDate: string) =>
      dateTimeFormatter(approvedDate, DateFormat.DATE),
  },
  {
    key: 14,
    title: "Status",
    dataIndex: "status",
    sorter: true,
    render: (_, record) => {
      const className =
        "active-status--" + record.status?.split("_")?.join("-");
      return (
        <span
          className={`text-success text-capitalize active-status ${className}`}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          {removeUnderscore(record.getLoanStatusLabel() ?? "")}
        </span>
      );
    },
  },
];