/* eslint-disable react-hooks/exhaustive-deps */
import React, { ChangeEvent, useEffect, useState } from "react";
import "./velichamLoan.scss";
import { Row, Image, Col, Button, Input, Drawer, Switch } from "antd";
import logo from "../../Assets/images/velichamLogo.png";
import VelichamRepayment from "./VelichamRepayment";
import useDrawer from "../../shared/hooks/useDrawer";
import { LoanService } from "../../services/Loan/loan.service";
import { LoanEMI } from "../../models/loanEMI.model";
import { fillEmptyData } from "../../shared/utils/fillEmptyData";
import * as appRoutes from "../../routes/routeConstants/appRoutes";
import {
  currencyFormatter,
  dateTimeFormatter,
  percentageFormatter,
} from "../../shared/utils/formatter";
import AppLoader from "../../shared/components/AppLoader";
import { QueryParams } from "../../models/query.params.model";
import { DateFormat } from "../../enums/dateFormat.type";
import { redirectToURL } from "../../shared/utils/redirectToUrl";
import { generatePaymentRedirectionURL } from "../../shared/utils/paymentActions";

const VelichamLoan = () => {
  const [loanId, setLoanId] = useState("");
  const [isManualEntry, setIsManualEntry] = useState(false);
  const [inputDueAmount, setInputDueAmount] = useState<number>();
  const [, setpaymentLink] = useState("");
  const [loanDetails, setLoanDetails] = useState<LoanEMI>();

  const {
    getLoanEMIDetails,
    loading,
    getRepaymentEMISchedule,
    loanPayEMI,
    repaymentPagination: pagination,
    loanSchedule,
    confirmDuePayment,
  } = LoanService();

  useEffect(() => {
    if (loanDetails?.loanId) {
      populateRepaymentSchedule();
    }
  }, [loanDetails]);

  const handleLoanDetails = async () => {
    const details = await getLoanEMIDetails(loanId);
    if (details) setLoanDetails(details);
  };

  const populateRepaymentSchedule = async (params?: QueryParams) =>
    await getRepaymentEMISchedule(String(loanDetails?.loanId), params);

  const {
    visible: isRepaymentSchedule,
    toggleVisibility: toggleRepaymentSchedule,
  } = useDrawer({});
  const loanSummary = [
    {
      label: "Customer name",
      value: fillEmptyData(loanDetails?.customerName),
    },
    {
      label: "Loan ID",
      value: fillEmptyData(loanDetails?.loancode),
    },
    {
      label: "Branch",
      value: fillEmptyData(loanDetails?.branch),
    },
    {
      label: "Loan Amount",
      value: currencyFormatter({
        amount: loanDetails?.loanAmount ?? 0,
        hasCurrencySign: true,
      }),
    },

    {
      label: "Tenure",
      value: loanDetails?.tenure ? `${loanDetails?.tenure} Month` : "NA",
    },
    {
      label: "ROI",
      value: percentageFormatter({
        value: Number(loanDetails?.roi),
      }),
    },
    {
      label: "Outstanding Balance",
      value: currencyFormatter({
        amount: loanDetails?.outstandingBalance ?? 0,
        hasCurrencySign: true,
      }),
    },
  ];

  const handleLoanChange = (e: ChangeEvent<HTMLInputElement>) =>
    setLoanId(e?.target?.value);

  const getPayableAmount = () =>
    Math.max(
      (loanDetails?.dueAmount ?? 0) - (loanDetails?.walletAmount ?? 0),
      0
    );

  const handlePayEMI = async () => {
    const payableAmount = getPayableAmount();
    const loanCode = loanDetails?.loancode;
    const instalmentId = loanDetails?.instalmentId;

    if (payableAmount === 0 && !inputDueAmount) {
      const data = await confirmDuePayment({
        otherAmount: 0,
        id: instalmentId,
      });
      if (data) {
        setTimeout(() => {
          redirectToURL(appRoutes.VELICHAM_LOAN);
        }, 3000);
      }
    } else {
      const inputAmount = inputDueAmount ?? payableAmount;
      const data = await loanPayEMI({
        loanCode,
        otherAmount: inputAmount,
        instalmentId,
        payEMI: "true",
      });

      if (data?.paymentLink) {
        setpaymentLink(data?.paymentLink);
        const url = generatePaymentRedirectionURL(
          appRoutes.PAYMENT_REDIRECTION,
          data?.paymentLink
        );
        window.open(url);
        redirectToURL(appRoutes.VELICHAM_LOAN);
      }
    }
  };

  const toogleManualEntry = () => {
    setIsManualEntry(!isManualEntry);
    setInputDueAmount(undefined);
  };
  const handleDueAmount = (e: ChangeEvent<HTMLInputElement>) =>
    setInputDueAmount(Number(e?.target?.value));

  return (
    <div className="velicham-loan">
      <div className="loan-container">
        <Row className="header" justify={"space-between"}>
          <Col xs={24} sm={24} md={8} lg={8} className="header__logo">
            <Image className="logo" src={logo} alt="logo" preview={false} />
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={16}
            className="title text-right mb-5"
          >
            Velicham Finance Private Limited
          </Col>
        </Row>

        {loanDetails ? (
          <div className="back">
            <p className="text-link">{"< BACK"}</p>
          </div>
        ) : (
          <Row className="loan-wrapper">
            <Col span={24} className="mb-5 loan-wrapper__label">
              Enter your Loan ID to view outstanding dues and make your payment
            </Col>
            <Col span={24} className="mt-5 loan-wrapper__content">
              <Row
                className="loan-wrapper__content-box"
                justify="center"
                align="middle"
                gutter={[0, 20]}
              >
                <Col xs={24} sm={24} md={24} lg={4} className="text-bold">
                  Enter your Loan ID
                </Col>
                <Col xs={24} sm={24} md={24} lg={8} className="loan-input">
                  <Input required onChange={handleLoanChange} />
                </Col>
                <Col xs={24} sm={24} md={24} lg={4} className="text-bold">
                  <Button onClick={handleLoanDetails} disabled={!loanId}>
                    SUBMIT
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        {loading ? (
          <AppLoader loading={loading} />
        ) : (
          loanDetails && (
            <Row className="loan-details" gutter={[0, 15]}>
              <Col
                xs={24}
                sm={24}
                md={16}
                lg={16}
                className="loan-details__wrapper"
              >
                <div className="mb-5">
                  <div className="heading mr-2">
                    Loan Details
                    <span>
                      <p
                        className="loan-details__schedule ml-5 text-link"
                        onClick={() => toggleRepaymentSchedule(true)}
                      >
                        {`REPAYMENT SCHEDULE  >`}
                      </p>
                    </span>
                  </div>
                </div>
                <Row gutter={[0, 20]}>
                  {loanSummary?.map((data, index) => (
                    <Col
                      xs={12}
                      sm={12}
                      md={8}
                      lg={8}
                      key={index}
                      className="size-16"
                    >
                      <div className="block__label"> {data?.label}</div>{" "}
                      <span className="block__value">{data?.value}</span>
                    </Col>
                  ))}
                </Row>
              </Col>

              <Col xs={24} sm={24} md={8} lg={8} className="due-details">
                <div className="heading mr-5">Due Details</div>
                <Row gutter={[0, 15]} className="size-16">
                  <Col xs={12} sm={12} md={12}>
                    <div className="block__label mt-5"> Due Date</div>{" "}
                    <span className="block__value">
                      {dateTimeFormatter(loanDetails?.dueDate, DateFormat.DATE)}
                    </span>
                  </Col>
                  <Col xs={12} sm={12} md={12}>
                    <div className="block__label mt-5"> Due Amount</div>
                    <span className="block__value">
                      {currencyFormatter({
                        amount: loanDetails?.dueAmount ?? 0,
                        hasCurrencySign: true,
                      })}
                    </span>
                  </Col>
                  {loanDetails?.walletAmount ? (
                    <>
                      <Col xs={12} sm={12} md={12}>
                        <div className="block__label mt-5"> Wallet Amount</div>
                        <span className="block__value">
                          {currencyFormatter({
                            amount: loanDetails?.walletAmount ?? 0,
                            hasCurrencySign: true,
                          })}
                        </span>
                      </Col>
                      <Col xs={12} sm={12} md={12}>
                        <div className="block__label mt-5"> Payable Amount</div>{" "}
                        <span className="block__value">
                          {`₹ ${getPayableAmount()}`}
                        </span>
                      </Col>
                    </>
                  ) : (
                    ""
                  )}

                  {loanDetails?.dueAmount ? (
                    <Col xs={24} sm={24} md={24}>
                      {getPayableAmount() > 0 && (
                        <div className="mb-5">
                          <span className="mr-5">Other amount</span>
                          <Switch
                            checked={isManualEntry}
                            onChange={toogleManualEntry}
                          />
                        </div>
                      )}
                      {isManualEntry && (
                        <Input
                          type="number"
                          onChange={handleDueAmount}
                          placeholder={"Enter Due Amount"}
                          value={inputDueAmount}
                        />
                      )}

                      <Button
                        className="loan-btn mt-5"
                        onClick={handlePayEMI}
                        disabled={isManualEntry && !inputDueAmount}
                      >
                        PAY EMI
                      </Button>
                    </Col>
                  ) : (
                    <span className="text-danger mt-5">
                      No outstanding payments are currently due
                    </span>
                  )}
                </Row>
              </Col>
            </Row>
          )
        )}
      </div>

      <Drawer
        placement="right"
        onClose={() => {
          toggleRepaymentSchedule(false);
        }}
        open={isRepaymentSchedule}
        width="100%"
        title="Repayment Schedule"
        destroyOnClose
      >
        <VelichamRepayment
          loanDetails={loanDetails}
          handleRepaymentSchedule={populateRepaymentSchedule}
          loanSchedule={loanSchedule}
          pagination={pagination}
        />
      </Drawer>
    </div>
  );
};

export default VelichamLoan;
