import React from "react";
import { ColumnsType } from "antd/lib/table";
import { LoanTypeModel } from "../../../models/loanType.model";
import { fillEmptyData } from "../../../shared/utils/fillEmptyData";

export const columns: ColumnsType<LoanTypeModel> = [
  {
    dataIndex: "label",
    title: "Loan Type",
  },
  {
    dataIndex: "minAndMaxAmount",
    title: "Min and Max Amount",
    render: (_: string, loan: LoanTypeModel) =>
      fillEmptyData(`${loan?.minimumAmount}- ${loan?.maximumAmount}`),
  },
  {
    dataIndex: "minAndMaxTenure",

    title: "Min and Max Tenure",
    render: (_: string, loan: LoanTypeModel) =>
      fillEmptyData(`${loan?.minimumTenure}- ${loan?.maximumTenure} Month`),
  },
  {
    dataIndex: "minAndMaxInterest",

    title: "Min and Max Interest",
    render: (_: string, loan: LoanTypeModel) =>
      fillEmptyData(`${loan?.minimumInterest}- ${loan?.maximumInterest}%`),
  },
  {
    title: "Status",
    dataIndex: "isActive",
    render: (isActive) => (
      <span
        className={`active-status text-capitalize text-${
          isActive ? "success" : "danger"
        }`}
      >
        {isActive ? "enabled" : "disabled"}
      </span>
    ),
  },
];
