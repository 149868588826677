import { alias, serializable } from "serializr";

export class VivitriDetails {
    @serializable(alias("application_id"))
    applicationId?: string

    @serializable
    status?: string

    @serializable(alias("loan_id"))
    loanId?: number

    @serializable(alias("account_no"))
    accountNumber?: number
}