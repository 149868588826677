export enum ButtonType {
    SUBMIT = "submit",
    LINK = "link",
    DEFAULT = "default",
    PRIMARY = "primary",
}

export enum HTMLButtonType {
    SUBMIT = "submit",
    RESET = "reset",
}