/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import TabExtraContent from "../../../shared/components/TabExtraContent";
import useQueryParams from "../../../shared/hooks/useQueryParams";
import { Loan, LoanParams } from "../../../models/loan.model";
import useDrawer from "../../../shared/hooks/useDrawer";
import "./pipelineLoans.scss";
import '../pipeline.scss'
import FilterButtons from "../../../shared/components/FilterButtons";
import { Filters } from "../../../models/filters.model";
import { LoanService } from "../../../services/Loan/loan.service";
import { Drawer, Table, TableColumnsType, TableProps } from "antd";
import { columns } from "../PipelineWrapper/columns";
import useTableConfig from "../../../shared/hooks/useTableConfig";
import useSorting from "../../../shared/hooks/useSorting";
import { ColumnsType, SorterResult } from "antd/lib/table/interface";
import { generatePath, useNavigate } from "react-router-dom";
import * as AppRoutes from "../../../routes/routeConstants/appRoutes";
import LoanFilters from "../../Loans/LoanFilters";
import ColumnOptions from "../../../shared/components/ColumnOptions";
import { DateRange } from "../../../shared/types/date.type";
import CustomRangePicker from "../../../shared/components/CustomRangePicker";
import { TableConfig } from "../../../enums/tableConfig.type";
import { AllLoanTypes } from "../../../enums/pipelineLoanTypes";
import LoanAmountDetails from "../../../shared/components/LoanDetails";
import { ColumnOrder } from "../../../enums/columnOrder.enum";
import { getColumnKeys, getColumnTitles, getDefaultColumns, getDeletedColumns, hasColumnDifference } from "../../../shared/utils/columnUtil";
import { LocalStorageHelper } from "../../../shared/utils/localStorageHelper";
import { LocalStorage } from "../../../enums/localStorage.enum";
import { ColumnsService } from "../../../services/Columns/columns.service";

const sortBy = {
  code: "code",
  name: "customer_name",
  centerName: "center_name",
  createdByFirstName: "created_by.firstname",
  loanTypeName: "loan_type_name",
  status: "status",
  organizationName: "organisation_name",
};

const PipelineLoans = () => {
  const { getLoans, loading } = LoanService();
  const handleUpdateParams = () => updateParams(getParams());

  const handleDateChange = (date: DateRange) => {
    setDateRange(date);
    updateParams({ ...pipelineParams, ...date });
  };

  const [, setDateRange] = useState<DateRange>();
  const [filters, setFilters] = useState<Filters>(new Filters());
  const [defaultColumns, setDefaultColumns] =
    useState<TableColumnsType<Loan>>(columns);
  const [loans, setLoans] = useState<Loan[]>([]);
  const { columnConfig, setColumnConfig, getColumnOrder } = useTableConfig();
  const [totalLoans, setTotalLoans] = useState(1);
  const [filteredColumns, setFilteredColumns] = useState<string[]>(
    columnConfig?.loans ?? []
  );
  const { updateSortData } = useSorting<Loan>({ sortBy });
  const navigate = useNavigate();

  const {
    params: pipelineParams,
    handleSearch,
    updateParams,
    getParams,
  } = useQueryParams<LoanParams>({
    params: new LoanParams(),
  });

  const {
    visible: pipelineFilterVisible,
    toggleVisibility: togglePipelineFilterVisibility,
  } = useDrawer({});

  const {
    visible: pipelineSettingsVisible,
    toggleVisibility: togglePipelineSettingsVisibility,
  } = useDrawer({});

  const handleChange: TableProps<Loan>["onChange"] = (
    pagination,
    _,
    sorter
  ) => {
    const sortData = updateSortData(sorter as SorterResult<Loan>);
    updateParams({
      ...pipelineParams,
      ...sortData,
      page: pagination?.current,
    });
  };

  useEffect(() => {
    const fetchLoans = async () => {
      const updatedParams = {
        ...pipelineParams,
        searchText: pipelineParams.searchText,
        pipeline: true,
      };
      const loanData = await getLoans(updatedParams);
      if (loanData?.loans) setLoans(loanData?.loans);
      if (loanData?.meta) {
        setTotalLoans(loanData?.meta?.totalCount ?? 1);
        loanData?.meta?.filters && setFilters(loanData?.meta?.filters);
      }
    };
    fetchLoans();
  }, [pipelineParams, pipelineParams.searchText]);

  useEffect(() => {
    const { toDate, fromDate } = getParams();
    setDateRange({ toDate, fromDate });
  }, []);

  useEffect(() => {
    setColumnConfig(TableConfig.LOANS, filteredColumns);
  }, [filteredColumns]);

  const setColumns = () => {
    if (originalOrder && originalOrder.length > 0) {
      const updatedColumns = getDefaultColumns(columns, storedColumnOrder, storedColumnKeys, originalOrder);
      setDefaultColumns(updatedColumns);
    } else {
      setDefaultColumns(columns);
    }
  }
  useEffect(() => {
    fetchColumnOrder()
  }, []);

  const fetchColumnOrder = async () => {
    const columnOrder = await getColumns({
      pageName: ColumnOrder.PIPELINE_LOANS,
      employeeId: employee.id
    })
    if (!columnOrder) {
      publishColumns()
    }
    setOriginalOrder(columnOrder?.columnArray ?? [])
    setColumnConfig(ColumnOrder.PIPELINE_LOANS, columnOrder?.columnArray ?? [])
  }

  const setDeletedColumns = () => {
    setFilteredColumns(getColumnTitles(getDeletedColumns(columns, originalOrder)))
  }
  const tableColumnTitles = getColumnTitles(columns)
  const storedColumnOrder = getColumnOrder(ColumnOrder.PIPELINE_LOANS);
  const storedColumnKeys = getColumnKeys(storedColumnOrder)
  const employee = LocalStorageHelper.getItem(LocalStorage.EMPLOYEE);
  const [originalOrder, setOriginalOrder] = useState<string[]>([])
  const { createColumns, getColumns, updateColumns } = ColumnsService()

  const publishColumns = async () => {
    await createColumns({
      pageName: ColumnOrder.PIPELINE_LOANS,
      columnArray: tableColumnTitles,
      employeeId: employee?.id
    })
  }

  useEffect(() => {
    if (hasColumnDifference(columns, defaultColumns)) {
      publishColumns();
    }
  }, []);

  useEffect(() => {
    if (originalOrder && originalOrder.length) {
      setColumns();
      setDeletedColumns()
    }
  }, [originalOrder]);

  const updateColumn = async (columns: ColumnsType<Loan>) => {
    await updateColumns({
      columnArray: getColumnTitles(columns),
      pageName: ColumnOrder.PIPELINE_LOANS
    })
  }

  return (
    <div className="pipeline-loans">
      {!loading && (
        <FilterButtons filters={filters} onFilter={handleUpdateParams} />
      )}
      <LoanAmountDetails params={pipelineParams} type={AllLoanTypes.PIPELINE} />
      <Table
        scroll={{ x: true }}
        className="pipeline-loans-table"
        columns={defaultColumns.filter(
          (col) => !filteredColumns.includes(col.title as string)
        )}
        dataSource={loans}
        loading={loading}
        onChange={handleChange}
        rowKey={(record: any) => record?.id}
        onRow={(record) => ({
          onClick: () => {
            navigate(
              generatePath(AppRoutes.LOAN_PIPELINE_DETAIL, {
                loanId: record?.id,
              }),
              {
                state: {
                  disbursedDate: record?.recentLoanStatus?.assignedAt,
                },
              }
            );
          },
        })}
        pagination={{
          current: pipelineParams?.page,
          hideOnSinglePage: true,
          total: totalLoans,
        }}
      />
      <div className="pipeline-extra-content">
        <CustomRangePicker
          className="mr-5 custom-range-picker"
          onChange={handleDateChange}
          values={{
            toDate: pipelineParams?.toDate,
            fromDate: pipelineParams?.fromDate,
          }}
        />
        <TabExtraContent
          searchProps={{
            onSearch: handleSearch,
            placeholder: "Search Loan",
            value: pipelineParams.searchText,
          }}
          filterClick={togglePipelineFilterVisibility}
          settingsClick={togglePipelineSettingsVisibility}
        />
      </div>
      {pipelineFilterVisible && (
        <Drawer
          placement="right"
          onClose={() => togglePipelineFilterVisibility(false)}
          visible={pipelineFilterVisible}
          width={"70vw"}
          title="Filters"
          closable
          destroyOnClose
        >
          <LoanFilters
            onClose={() => togglePipelineFilterVisibility(false)}
            onFilter={updateParams}
          />
        </Drawer>
      )}

      {pipelineSettingsVisible && (
        <Drawer
          placement="right"
          onClose={() => togglePipelineSettingsVisibility(false)}
          visible={pipelineSettingsVisible}
          width={500}
          title="Column Options"
          destroyOnClose
        >
          <ColumnOptions
            defaultColumns={defaultColumns}
            filteredColumns={filteredColumns}
            setFilteredColumns={setFilteredColumns}
            onClose={() => togglePipelineSettingsVisibility()}
            setDefaultColumns={setDefaultColumns}
            tableColumns={columns}
            onAction={updateColumn}
            tableKey={ColumnOrder.PIPELINE_LOANS}
          />
        </Drawer>
      )}
    </div>
  );
};

export default PipelineLoans;
