import { alias, primitive, serializable } from "serializr";

export class CreditScore {
    @serializable(alias("credit_score", primitive()))
    creditScore?: number
    @serializable(alias("dpd_days", primitive()))
    dpdDays?: number
    @serializable(alias("dpd_amount", primitive()))
    dpdAmount?: number
    @serializable(alias("active_loans_outstanding", primitive()))
    activeLoansOutstanding?: number
    @serializable(alias("enquiry_no", primitive()))
    enquiryNo?: number
    @serializable(alias("cb_credit_score", primitive()))
    cbCreditScore?: number
    @serializable(alias("cb_enquiry_number", primitive()))
    cbEnquiryNumber?: number
    @serializable(alias('loan_agreement_id', primitive()))
    loanAgreementId?: string
    @serializable(alias('special_approval_reason', primitive()))
    specialApprovalReason?: string | null
    @serializable(alias('special_approval', primitive()))
    specialApproval?: boolean
    @serializable(alias('is_ntc', primitive()))
    isNTC?: boolean
    @serializable(alias('mas_api_call', primitive()))
    masApiCall?: boolean

    @serializable(alias("total_overdue_amount_as_on_date"))
    totalOverDueAmountAsOnDate?: number

    @serializable(alias("max_dpd_tradeline_three_months"))
    maxDpdTradelineThreeMonths?: number

    @serializable(alias("max_dpd_tradeline_six_months"))
    maxDpdTradelineSixMonths?: number

    @serializable(alias("max_dpd_tradeline_nine_months"))
    maxDpdTradelineNineMonths?: number

    @serializable(alias("max_dpd_tradeline_twelve_months"))
    maxDpdTradelineTwelveMonths?: number

    @serializable(alias("bureau_score"))
    bureauScore?: number

    @serializable(alias("co_borrower_total_overdue_amount_as_on_date"))
    coBorrowerTotalOverDueAmountAsOnDate?: number

    @serializable(alias("co_borrower_max_dpd_tradeline_three_months"))
    coBorrowerMaxDpdTradelineThreeMonths?: number

    @serializable(alias("co_borrower_max_dpd_tradeline_six_months"))
    coBorrowerMaxDpdTradelineSixMonths?: number

    @serializable(alias("co_borrower_max_dpd_tradeline_nine_months"))
    coBorrowerMaxDpdTradelineNineMonths?: number

    @serializable(alias("co_borrower_max_dpd_tradeline_twelve_months"))
    coBorrowerMaxDpdTradelineTwelveMonths?: number

    @serializable(alias("co_borrower_bureau_score"))
    coBorrowerBureauScore?: number
}