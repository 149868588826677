export const HeadOfficerDashboardConstants = {
    AVERAGE_CT_TAT: "Average CT TAT",
    LOAN_APPLICATIONS_CT: "CT Verification Loan Applications",
    LOAN_APPROVALS_CT: "CT Verification Loan Approvals",
    TOTAL_LOANS: "Total Loans",
    TOTAL_AMOUNT: "Total Amount",
    APPLICATIONS_BAR_COLOR: "#22efb0",
    APPROVAL_BAR_COLOR: "#2276ef",
    APPROVED_LOANS: "Approved Loans",
    LOAN_APPLICATIONS: "Loan Applications",
    LOAN_APPROVALS: "Loan Approvals",
    BRANCH_PLACEHOLDER: "Select Branch",
    LOAN_PLACEHOLDER: "Select Loan"
}