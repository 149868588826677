import { Form, Formik } from 'formik'
import React from 'react'
import CustomModal from '../../../../shared/components/CustomModal'
import { UpdateLoanAmountConstants } from './constants'
import { UpdateLoanAmountValidation } from './validation'
import { UpdateLoanAmountModel } from "../../../../models/updateLoanAmount.model"
import { Button, Col, Row } from 'antd'
import { ButtonType, HTMLButtonType } from '../../../../enums/buttonType'
import { Buttons } from '../../../../enums/buttons.enum'
import InputField from '../../../../shared/components/InputField'
import { InputType } from '../../../../enums/input.type'
import { LoanService } from '../../../../services/Loan/loan.service'
import "./updateLoanAmount.scss"
import { Loan } from '../../../../models/loan.model'

interface UpdateLoanAmountProps {
    visible: boolean
    toggleVisibility: () => void
    loan: Loan
    refetchLoans: () => void
    refetchCustomer: () => void
}
const UpdateLoanAmount = (props: UpdateLoanAmountProps) => {
    const { updateLoanAmount } = LoanService()
    const { visible, toggleVisibility, loan, refetchLoans, refetchCustomer } = props
    const handleSubmit = async (values: UpdateLoanAmountModel) => {
        const response = await updateLoanAmount({
            amount: values.amount,
            loanId: String(loan?.id)
        })
        if (response) {
            toggleVisibility()
            refetchCustomer()
            refetchLoans()
        }
    }

    const inputOnChange = (e: React.ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: string) => void, setFieldTouched: (field: string, value: boolean) => void) => {
        setFieldValue(UpdateLoanAmountConstants.FIELD, e.target.value)
        setFieldTouched(UpdateLoanAmountConstants.FIELD, true)
    }

    return (
        <div>
            <Formik
                validationSchema={UpdateLoanAmountValidation(loan.requestedLoanAmt)}
                initialValues={new UpdateLoanAmountModel()}
                onSubmit={handleSubmit}
                validateOnChange
                validateOnBlur
            >
                {({ isValid, dirty, setFieldValue, setFieldTouched }) => {

                    return (
                        <CustomModal
                            title={UpdateLoanAmountConstants.TITLE}
                            visible={visible}
                            onCancel={toggleVisibility}
                            isOkButton={false}
                            isCancelButton={false}
                            className='updateLoanAmount'
                        >
                            <Form className='ant-form-vertical'>
                                <Row>
                                    <Col span={24}>
                                        <InputField
                                            name={UpdateLoanAmountConstants.FIELD}
                                            placeholder={UpdateLoanAmountConstants.PLACEHOLDER}
                                            type={InputType.NUMBER}
                                            label={UpdateLoanAmountConstants.LABEL}
                                            onChange={(e) => inputOnChange(e, setFieldValue, setFieldTouched)}
                                        />
                                    </Col>
                                    <Col span={24}>
                                        <div className="drawer-footer text-right upload-modal-footer">
                                            <Button
                                                className="drawer-footer__cancel mr-2"
                                                htmlType={HTMLButtonType.RESET}
                                                onClick={toggleVisibility}
                                            >
                                                {Buttons.CANCEL}
                                            </Button>
                                            <Button
                                                htmlType={HTMLButtonType.SUBMIT}
                                                disabled={!dirty || !isValid}
                                                type={ButtonType.DEFAULT}
                                                className="ml-5 mod-upload-button drawer-footer__submit"
                                            >
                                                {Buttons.SUBMIT}
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </CustomModal>
                    );
                }}
            </Formik>
        </div>
    )
}

export default UpdateLoanAmount