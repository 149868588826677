import { BCStatusTags } from "../../enums/currentStatusTags.type";

export const getBCStatusLabelSwitch = (status?: string) => {
    switch(status) {
        case BCStatusTags.LOAN_AGREEMENT_PENDING:
            return "Loan Agreement Pending";
        case BCStatusTags.LOAN_AGREEMENT_UPLOADED:
            return "Loan Agreement Uploaded";
        case BCStatusTags.MASS_API_FAILURE:
            return "Mass API Failure";
        case BCStatusTags.MASS_API_IN_PROGRESS:
            return "Mass API In Progress";
        case BCStatusTags.MASS_REJECTED:
            return "MAS Rejected"
        case BCStatusTags.AGREEMENT_UPLOADED_AND_CONFIRMED:
            return "Agreement Uploaded and Confirmed";
        case BCStatusTags.API_LOGIN_SUCCESSFUL:
            return "API Login Successful";
        case BCStatusTags.FILE_APPROVED_AND_AGREEMENT_PENDING:
            return "File Approved and Agreement Pending"
        case BCStatusTags.LOAN_DISBURSED:
            return "Loan Disbursed";
        case BCStatusTags.LOAN_DISBURSEMENT_IN_PROGRESS:
            return "Loan Disbursement in Progress"
        case BCStatusTags.UGRO_API_FAILED:
            return "UGRO API Failed"
        case BCStatusTags.UGRO_API_SUCCESS:
            return "UGRO API Successful"
        case BCStatusTags.UGRO_DOCUMENT_UPLOADED_SUCCESSFULLY:
            return "UGRO Document Uploaded Successfully"
        case BCStatusTags.UGRO_DOCUMENT_UPLOAD_FAILED:
            return "UGRO Document Upload Failed"
        case BCStatusTags.LOAN_AGREEMENT_UPLOAD_IN_PROGRESS:
            return "Loan Agreement Upload in Progress"
        case BCStatusTags.SCHEDULE_UPLOADED_SUCCESSFULLY:
            return "Schedule Uploaded Successfully"
        case BCStatusTags.VCL_APPROVED:
                return "VCL Approved"
        case BCStatusTags.VCL_REJECTED:
                return "VCL Rejected"
        case BCStatusTags.READY_TO_SEND_BACK:
                return "VCL Ready to send Back"
        case BCStatusTags.SENT_TO_VCL:
                return "Sent to VCL"
        default:
      return status;
    }
}