import { alias, serializable } from "serializr";
export class VivitriCoBorrower {
    @serializable(alias("cb_total_overdue_amount"))
    cbTotalOverDueAmount?: number

    @serializable(alias("cb_max_dpd_tradeline_3_months"))
    cbMaxDpdTradeline3Months?: number

    @serializable(alias("cb_max_dpd_tradeline_6_months"))
    cbMaxDpdTradeline6Months?: number

    @serializable(alias("cb_max_dpd_tradeline_12_months"))
    cbMaxDpdTradeline12Months?: number

    @serializable(alias("cb_bureau_score"))
    cbBureauScore?: number

    @serializable(alias("cb_risk_events"))
    cbRiskEvents?: number
}
export class VivitriBorrowerForm extends VivitriCoBorrower {
    @serializable(alias("total_overdue_amount"))
    totalOverDueAmount?: number

    @serializable(alias("max_dpd_tradeline_3_months"))
    maxDpdTradeline3Months?: number

    @serializable(alias("max_dpd_tradeline_6_months"))
    maxDpdTradeline6Months?: number

    @serializable(alias("max_dpd_tradeline_12_months"))
    maxDpdTradeline12Months?: number

    @serializable(alias("bureau_score"))
    bureauScore?: number

    @serializable(alias("risk_events"))
    riskEvents?: number

}