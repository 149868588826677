/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Col, Row, Select, Spin } from "antd";
import DashStats from "../DashStats";
import StatsGraph from "../../../shared/components/StatsGraph";
import { AuthContext } from "../../../context/AuthContext";
import { DashboardService } from "../../../services/Dashboard/dashboardService.service";
import "../dashboard.scss";
import CustomRangePicker from "../../../shared/components/CustomRangePicker";
import { Graph } from '../../../enums/Graph.type';
import { getNCharacters } from "../../../shared/utils/getNCharacters";
import { DateRange } from "../../../shared/types/date.type";
import useBranch from "../../../shared/hooks/useBranch";
import { Meta } from "../../../models/meta.modal";

import { MetaService } from "../../../services/Meta/meta.service";
import {
  HeadOfficerDashboard as HeadOfficerDashboardModel,
  HeadOfficerDashboardParams,
} from "../../../models/headOfficerDashboard";
import { CardProps } from "../../../shared/types/card.type";
import { User } from "../../../enums/user.type";
import { HeadOfficerDashboardConstants } from "./constants";
import { DashboardGraph } from "../../../enums/graphType";
import { restrictMonths } from "../../../shared/utils/dateUtils";
import { Moment } from "moment";
import { DashboardParams } from "../../../models/dashboard.model";
import { DashboardConstants } from "../constants";

interface HeadOfficerDashboardProps {
  selectedRole?: string;
  onRoleChange: (role: User) => void;
}

interface GraphFilters {
  branch?: string;
  loanType?: string;
  loanApplication?: DateRange;
  loanApproval?: DateRange;
}

const HeadOfficerDashboard = (props: HeadOfficerDashboardProps) => {
  const { selectedRole, onRoleChange } = props;
  const { user } = AuthContext();
  const { branches, loading: branchLoading } = useBranch({ allBranch: true });
  const {
    getDashboard,
    getDashboardTAT,
    loading,
    tatLoading,
    getHeadofficerGraph,
  } = DashboardService();
  const { getLoanTypes } = MetaService();
  const [tatValue, setTatValue] = useState<number>();
  const [loanTypesOptions, setLoanTypeOptions] = useState<Meta[]>([]);
  const [dashboardGraph, setDashboardGraph] =
    useState<HeadOfficerDashboardModel>();
  const [dashboardData, setDashboardData] =
    useState<HeadOfficerDashboardModel>();
  const [filter, setFilter] = useState<GraphFilters>();

  useEffect(() => {
    handleGetDashboard({ user: selectedRole });
    fetchLoanTypes();
    handleGetDashboardTAT();
    handleDashboardGraph(Graph.APPLICATION);
    handleDashboardGraph(Graph.APPROVAL);
  }, []);
  const fetchLoanTypes = async () => {
    const data = await getLoanTypes();
    data && setLoanTypeOptions(data);
  };

  const handleGetDashboardTAT = async (params?: DashboardParams) => {
    const tatValue = await getDashboardTAT({
      fromStatus: DashboardConstants.CT_VERIFICATION_PARAM,
      toStatus: DashboardConstants.APPROVED_PARAM
    });
    tatValue && setTatValue(Number(tatValue));
  };

  const handleGetDashboard = async (params?: HeadOfficerDashboardParams) => {
    const data = await getDashboard(params);
    data && setDashboardData(data);
  };

  const handleFilter = (value: HeadOfficerDashboardParams) =>
    onRoleChange(value.user as User);

  const handleDateChange = (value: DateRange) => {
    handleGetDashboard({ ...value, user: User.HO })
    handleGetDashboardTAT(value);
  };

  const handleGraphData = (
    graphData: DashboardGraph
  ): { key: string; count: number; value: number }[] => {
    const months: string[] = (dashboardGraph?.[graphData]?.map((graph) => String(graph.month)) || [])
    return dashboardGraph
      ? months.map((key) => ({
          key,
          count:
            (dashboardGraph[graphData] || []).find(
              (item) => getNCharacters(String(item?.month), 3) === key
            )?.sum ?? 0,
          value:
            (dashboardGraph[graphData] || []).find(
              (item) => getNCharacters(String(item?.month), 3) === key
            )?.count ?? 0,
        }))
      : [];
  };
  

  const card: CardProps[] = [
    {
      label: "CT Verification",
      count: dashboardData?.ctVerificationCount,
      value: dashboardData?.ctVerificationAmount,
    },
    {
      label: HeadOfficerDashboardConstants.AVERAGE_CT_TAT,
      count: tatValue,
      key: "tat",
      loading: tatLoading,
    },
    {
      label: "Approved Loans",
      count: dashboardData?.approvedCount,
      value: dashboardData?.approvedAmount,
    },
    {
      label: "Disbursed Loans",
      count: dashboardData?.disbursedCount,
      value: dashboardData?.disbursedAmount,
    },
    {
      label: "Pending e-Sign Loans",
      count: dashboardData?.pendingEsignCount,
      value: dashboardData?.pendingEsignAmount,
    },
    {
      label: "Rejected Loans",
      count: dashboardData?.rejectedCount,
      value: dashboardData?.rejectedAmount,
    },
  ];
  const handleDashboardGraph = async (
    type: Graph,
    values?: DateRange,
    filter?: HeadOfficerDashboardParams
  ) => {
    const data = await getHeadofficerGraph({ ...values, ...filter }, type);
    data &&
      setDashboardGraph((existingData) => ({
        ...existingData,
        [type === Graph.APPLICATION ? "loanApplications" : "loanApprovals"]:
          data,
      }));
  };

  const onChangeGraph = (values: DateRange, graphType: Graph) => {
    const graphFilters = graphType === Graph.APPLICATION ? { loanType: filter?.loanType, loanApplication: values } : { branch: filter?.branch, loanApproval: values }
    handleDashboardGraph(graphType, values, {
      ...filter,
      ...graphFilters
    })
  }

  const restrictMonthsInGraph = (
    current: Moment,
    selectedFromDate: Moment | null,
    maxMonths: number = 11
  ): boolean => {
    return !!selectedFromDate && restrictMonths(current, selectedFromDate, maxMonths);
  };  

  return (
    <div className="head-officer dashboard">
      {loading && (
        <div className="spin-overlay">
          <Spin />
        </div>
      )}
      <Row gutter={[30, 16]}>
        <Col span={10}>
          <DashStats
            employee={user?.employee}
            card={card}
            onFilter={handleFilter}
            role={selectedRole}
            onDateChange={handleDateChange}
          />
        </Col>
        <Col span={14}>
          {dashboardGraph?.loanApplications && (
            <StatsGraph
              title={HeadOfficerDashboardConstants.LOAN_APPLICATIONS_CT}
              hasCard
              data={handleGraphData(DashboardGraph.LOAN_APPLICATION)}
              axes={{
                x: HeadOfficerDashboardConstants.TOTAL_LOANS,
                y: HeadOfficerDashboardConstants.TOTAL_AMOUNT,
              }}
              barColor={HeadOfficerDashboardConstants.APPLICATIONS_BAR_COLOR}
              extraContent={
                <Row gutter={[20, 20]}>
                  <Col span={14}>
                    <CustomRangePicker
                      onChange={(values) => onChangeGraph(values, Graph.APPLICATION)}
                      disabledDateCondition={restrictMonthsInGraph}
                    />
                  </Col>
                  <Col span={10}>
                    <Select
                      options={loanTypesOptions}
                      placeholder="Select Loan"
                      onChange={(loanType) => {
                        handleDashboardGraph(
                          Graph.APPLICATION,
                          filter?.loanApplication,
                          { loanType }
                        );
                        setFilter({ ...filter, loanType });
                      }}
                      allowClear
                    />
                  </Col>
                </Row>
              }
            />
          )}

          {dashboardGraph?.loanApprovals && (
            <StatsGraph
              title={HeadOfficerDashboardConstants.LOAN_APPROVALS_CT}
              hasCard
              data={handleGraphData(DashboardGraph.LOAN_APPROVALS)}
              axes={{
                x: HeadOfficerDashboardConstants.TOTAL_LOANS,
                y: HeadOfficerDashboardConstants.TOTAL_AMOUNT,
              }}
              barColor={HeadOfficerDashboardConstants.APPROVAL_BAR_COLOR}
              extraContent={
                <Row gutter={[20, 20]}>
                  <Col span={14}>
                    <CustomRangePicker
                      onChange={(values) => onChangeGraph(values, Graph.APPROVAL)}
                      disabledDateCondition={restrictMonthsInGraph}
                    />
                  </Col>
                  <Col span={10}>
                    <Select
                      className="select-branches"
                      options={branches}
                      placeholder="Select Branch"
                      loading={branchLoading}
                      onChange={(branch) => {
                        handleDashboardGraph(
                          Graph.APPROVAL,
                          filter?.loanApproval,
                          { branch }
                        );
                      }}
                      allowClear
                    />
                  </Col>
                </Row>
              }
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default HeadOfficerDashboard;
