export const loanServiceConstants = {
    GET_PRECLOSE_AMOUNT: {
        ERROR_MESSAGE: "Unable to Get Preclose Amount"
    },
    UPDATE_LOAN_AMOUNT: {
        ERROR_MESSAGE: "Unable to Update Loan Amount",
        SUCCESS_MESSAGE: "Successfully Updated the loan amount"
    },
    GET_VIVITRI_DETAILS: {
        ERROR_MESSAGE: "Unable to fetch Vivitri Details"
    },
    UPDATE_LOAN: {
        SUCCESS_MESSAGE: "Updated the Loan Successfully !"
    }
}