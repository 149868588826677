/* eslint-disable react-hooks/exhaustive-deps */
import "./customers.scss";
import React, { useEffect, useState } from "react";
import { CustomerParams } from "../../models/customer.model";
import Individual from "./Individual";
import useDrawer from "../../shared/hooks/useDrawer";
import useQueryParams from "../../shared/hooks/useQueryParams";
import { DateRange } from "../../shared/types/date.type";

const Customers = () => {
  const [dateRange, setDateRange] = useState<DateRange>();
  const {
    params: customerParams,
    getParams,
  } = useQueryParams({
    params: new CustomerParams(),
  });
  const {
    visible: customerFilterVisible,
    toggleVisibility: toggleCustomerFilterVisibility,
  } = useDrawer({});

  const {
    visible: customerSettingsVisible,
    toggleVisibility: toggleCustomerSettingsVisibility,
  } = useDrawer({});

  useEffect(() => {
    const { toDate, fromDate } = getParams();
    setDateRange({ toDate, fromDate });
  }, []);

  // const handleTabChange = (activeKey: string) => setActiveTab(activeKey);

  // const tabs: TabsProps[] = [
  //   {
  //     label: "Individual",
  //     key: "1",
  //     component: (
  //       <Individual
  //         dateRange={dateRange}
  //         params={customerParams}
  //         filterVisible={customerFilterVisible}
  //         onFilter={toggleCustomerFilterVisibility}
  //         settingsVisible={customerSettingsVisible}
  //         onSettings={toggleCustomerSettingsVisibility}
  //       />
  //     ),
  //   },
  // ];

  // const GroupTabLeftContent = (
  //   <TabExtraContent
  //     searchProps={{
  //       onSearch: () => {},
  //       placeholder: "Search Group",
  //     }}
  //     // filterClick={toggleGroupFilter}
  //     settingsClick={toggleCustomerSettingsVisibility}
  //     // exportClick={toggleGroupExport}
  //     // addClick={toggleGroupAdd}
  //   />
  // );

  return (
    <>
      <div className="customers">
        <Individual
          dateRange={dateRange}
          params={customerParams}
          filterVisible={customerFilterVisible}
          onFilter={toggleCustomerFilterVisibility}
          settingsVisible={customerSettingsVisible}
          onSettings={toggleCustomerSettingsVisibility}
        />
      </div>
    </>
  );
};

export default Customers;
